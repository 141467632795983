import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import DashboardCard from '../DashboardCard';
import InputField from '../InputField';
import PartnerService from '../../services/PartnerService';
import { loadAlert } from '../../userSlice';
import fields from '../CreatePartner/fields';

export default function UpdatePartner({ partnerId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm();
  const [loading, setLoading] = useState(true);
  // const [logo, setLogo] = useState('');
  const [partner, setPartner] = useState(null);

  useEffect(() => {
    PartnerService.get(partnerId)
      .then((partner) => {
        // setLogo(partner.logo);
        setPartner(partner);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [partnerId]);

  function onSubmit(data) {
    PartnerService.update(partnerId, data)
      .then((res) => {
        dispatch(
          loadAlert({
            type: 'info',
            message: `Partner "${res.name}" was updated.`,
          }),
        );
        history.push('/partners');
      })
      .catch(console.error);
  }

  return (
    <>
      <DashboardCard title={t(`components.updatePartner.title`)}>
        {loading && `loading...`}
        {!loading && (
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((field) => (
              <InputField
                key={field.name}
                label={t(`components.createPartner.form.${field.name}.label`)}
                defaultValue={partner[`${field.name}`]}
                name={field.name}
                placeholder={t(
                  `components.createPartner.form.${field.name}.placeholder`,
                )}
                inputRef={register({
                  required:
                    field.required &&
                    `${t(
                      `components.createPartner.form.${field.name}.required`,
                    )}`,
                  pattern: field.pattern,
                })}
                error={Boolean(errors[`${field.name}`])}
                helperText={
                  errors[`${field.name}`] && errors[`${field.name}`].message
                }
              />
            ))}
            {/* <InputField
              label={`Logo`}
              defaultValue={logo}
              name="logo"
              inputRef={register({ required: `Required` })}
              error={Boolean(errors.logo)}
              helperText={errors.logo ? errors.logo.message : `Partner Logo`}
            /> */}
            <div style={{ display: 'flex', gap: '1rem', marginTop: '0.5rem' }}>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
              >
                {t(`components.updatePartner.buttons.update`)}
              </Button>
              <Button
                variant="contained"
                disableElevation
                component={Link}
                to="/partners"
              >
                {t(`components.updatePartner.buttons.cancel`)}
              </Button>
            </div>
          </form>
        )}
      </DashboardCard>
    </>
  );
}

UpdatePartner.propTypes = {
  partnerId: PropTypes.string,
};
