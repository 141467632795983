import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import DashboardCard from '../DashboardCard';
import InputField from '../InputField';
import DeviceService from '../../services/DeviceService';
import { useDevice } from '../../utils/useDevice';

export default function DeviceUpdate({
  remoteDevice,
  setEditState,
  editState,
}) {
  const { register, handleSubmit, errors } = useForm();
  const [saving, setSaving] = useState(false);
  const [device, setDevice] = useState(false);
  const updatedDevice = useDevice(remoteDevice._id);
  const { t } = useTranslation();
  const deviceName = device?.name;

  useEffect(() => {
    setDevice(updatedDevice);
  }, [editState, updatedDevice]);

  const onSubmit = (data) => {
    setSaving(true);
    DeviceService.update(remoteDevice._id, data)
      .then((data) => {
        setSaving(false);
        setEditState(false);
        setDevice(data);
      })
      .catch(console.error);
  };

  return (
    <>
      {!device && <p>loading...</p>}
      {device && (
        <DashboardCard
          title={t('components.updateDevice.title', { deviceName })}
        >
          <fieldset style={{ border: 'none' }} disabled={saving}>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <InputField
                label={t('components.updateDevice.inputFields.name.label')}
                defaultValue={device.name}
                name="name"
                inputRef={register({
                  required: t('components.updateDevice.inputFields.required'),
                  validate: (value) => value.trim() !== '',
                  setValueAs: (value) => value.trim(),
                })}
                error={Boolean(errors.name)}
                helperText={
                  (errors.name && errors.name.message) ||
                  t('components.updateDevice.inputFields.name.helperText')
                }
              />
              <InputField
                label={t(
                  'components.updateDevice.inputFields.playlistMaxSize.label',
                )}
                defaultValue={device.playlistMaxSize}
                type="number"
                name="playlistMaxSize"
                inputRef={register({
                  required: t('components.updateDevice.inputFields.required'),
                  min: {
                    value: 1,
                    message: t(
                      'components.updateDevice.inputFields.playlistMaxSize.validationMessage',
                    ),
                  },
                  setValueAs: (value) => parseInt(value),
                })}
                error={Boolean(errors.playlistMaxSize)}
                helperText={
                  (errors.playlistMaxSize && errors.playlistMaxSize.message) ||
                  t(
                    'components.updateDevice.inputFields.playlistMaxSize.helperText',
                  )
                }
              />
              <InputField
                label={t(
                  'components.updateDevice.inputFields.displaySize.label',
                )}
                defaultValue={device?.displaySize}
                type="number"
                name="displaySize"
                inputRef={register({
                  required: t('components.updateDevice.inputFields.required'),
                  min: {
                    value: 1,
                    message: t(
                      'components.updateDevice.inputFields.displaySize.validationMessage',
                    ),
                  },
                  setValueAs: (value) => parseInt(value),
                })}
                error={Boolean(errors.displaySize)}
                helperText={
                  (errors.displaySize && errors.displaySize.message) ||
                  t(
                    'components.updateDevice.inputFields.displaySize.helperText',
                  )
                }
              />
              <InputField
                label={t(
                  'components.updateDevice.inputFields.displayType.label',
                )}
                defaultValue={device?.displayType}
                name="displayType"
                inputRef={register({
                  required: t('components.updateDevice.inputFields.required'),
                  validate: (value) => value.trim() !== '',
                  setValueAs: (value) => value.trim(),
                })}
                error={Boolean(errors?.displayType)}
                helperText={
                  (errors?.displayType && errors.name?.displayType) ||
                  t(
                    'components.updateDevice.inputFields.displayType.helperText',
                  )
                }
              />
              <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                <Button
                  type="submit"
                  disableElevation
                  variant="contained"
                  color="primary"
                >
                  {t('components.updateDevice.buttons.send')}
                </Button>
                <Button
                  onClick={() => setEditState(false)}
                  disableElevation
                  variant="contained"
                >
                  {t('components.updateDevice.buttons.cancel')}
                </Button>
              </div>
            </form>
          </fieldset>
        </DashboardCard>
      )}
    </>
  );
}

DeviceUpdate.propTypes = {
  remoteDevice: PropTypes.object.isRequired,
  setEditState: PropTypes.func.isRequired,
  editState: PropTypes.bool.isRequired,
};
