import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  font: {
    color: 'white',
    background: theme.palette.primary.light,
    padding: '0 8px',
    borderRadius: 3,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      fontSize: '100%',
    },
  },
}));

export default useStyles;
