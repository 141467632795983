import HttpClient from '../utils/httpClient';
import config from '../config';

let httpClient = new HttpClient(config.baseUrlDss);

export default {
  get,
  update,
  create,
  remove,
  updateHours,
};

async function get(locationId = '') {
  return await httpClient.get(`/locations/${locationId}`);
}

async function update(locationId = '', requestBody) {
  return await httpClient.put(`/locations/update/${locationId}`, requestBody);
}

async function create(requestBody) {
  return await httpClient.post(`/locations/new`, requestBody);
}

async function remove(locationId = '') {
  return await httpClient.delete(`/locations/delete/${locationId}`);
}

async function updateHours(locationId = '', reqBody) {
  return await httpClient.put(`/locations/update/${locationId}`, reqBody);
}
