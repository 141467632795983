import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import DashboardCard from '../DashboardCard';
import LocationService from '../../services/LocationService';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';

export default function LocationsList() {
  const [locations, setLocations] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    LocationService.get()
      .then((locations) => {
        setLocations(locations);
      })
      .catch(console.error);
  }, []);

  return (
    <>
      <DashboardCard title={t('components.locationsList.title')}>
        <List>
          {!locations.length ? (
            <ListItem>
              <ListItemAvatar>
                <Skeleton variant="circle" width={40} height={40} />
              </ListItemAvatar>
              <ListItemText primary={<Skeleton />} />
            </ListItem>
          ) : (
            <ListItem button component={Link} to={`/locations`}>
              <ListItemAvatar>
                <Avatar>
                  <LocationOnRoundedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${locations.length} ${t(
                  'components.locationsList.location',
                )}`}
              />
            </ListItem>
          )}
          {/* {(!locations.length ? Array.from(new Array(2)) : locations).map(
            (location, i) => (
              <ListItem
                key={location?._id || i}
                button
                component={Link}
                to={`/locations/details/${location?._id}`}
              >
                <ListItemAvatar>
                  {!location ? (
                    <Skeleton variant="circle" width={40} height={40} />
                  ) : (
                    <Avatar>{location?.name.charAt(0).toUpperCase()}</Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText primary={location?.name || <Skeleton />} />
              </ListItem>
            ),
          )} */}
        </List>
      </DashboardCard>
    </>
  );
}
