import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

export default function GMap(props) {
  return (
    <>
      <LoadScript googleMapsApiKey={process.env.GOOGLE_API_KEY}>
        <GoogleMap {...props}>{props.children}</GoogleMap>
      </LoadScript>
    </>
  );
}

GMap.propTypes = {
  children: PropTypes.node.isRequired,
};
