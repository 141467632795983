import React, { useEffect, useState } from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import { Helmet } from 'react-helmet';
import capitalize from 'capitalize';
import DeviceService from '../../services/DeviceService';
import PartnersList from '../../components/PartnersList';
import LocationsList from '../../components/LocationsList';
import DeviceStatusBar from '../../components/DeviceStatusBar';
import DevicesWithProblems from '../../components/DevicesWithProblems';
import { deviceStatuses, siteMetadata } from '../../constants';
import GoogleMapDevices from '../../components/GoogleMapDevices';

const center = { lat: 47.475926, lng: 19.069707 };
const zoom = 12;

export const SuccessCheckIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.success.main,
  },
}))(CheckCircleIcon);

export const RedErrorIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.error.main,
  },
}))(ErrorIcon);

export const YellowWarningIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.warning.main,
  },
}))(WarningIcon);

const Dashboard = () => {
  const [devices, setDevices] = useState(null);
  const [loading, setLoading] = useState(true);
  const activatedDevices = devices?.filter((device) => device.activated);
  const devicesWithProblems = activatedDevices?.filter(
    (device) =>
      device.status === deviceStatuses.ERROR ||
      device.status === deviceStatuses.WARNING,
  );
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    DeviceService.get()
      .then((res) => {
        setDevices(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${capitalize(t('components.menuItems.dashboard'))} | ${
          siteMetadata.TITLE
        }`}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DeviceStatusBar devices={devices} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DevicesWithProblems
            activatedDevices={activatedDevices}
            devicesWithProblems={devicesWithProblems}
          />
          <div style={{ margin: theme.spacing(3) }}></div>
          <GoogleMapDevices
            title={t('pages.dashboardPage.devices')}
            devices={devices}
            loading={loading}
            center={center}
            zoom={zoom}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PartnersList />
          <div style={{ margin: theme.spacing(3) }}></div>
          <LocationsList />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
