import React from 'react';
import { Helmet } from 'react-helmet';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import capitalize from 'capitalize';
import VideoTable from '../../components/VideoTable';
import { useTranslation } from 'react-i18next';
import { siteMetadata } from '../../constants';

const Videos = () => {
  const { t } = useTranslation();
  const pageName = capitalize(t('components.menuItems.videos'));

  return (
    <>
      <Helmet>
        <title>{`${pageName} | ${siteMetadata.TITLE}`}</title>
      </Helmet>
      <Button
        component={Link}
        disableElevation
        to="/videos/upload"
        variant="contained"
        color="secondary"
        style={{ margin: '0 0 16px 0' }}
      >
        {t('pages.videos.upload')}
      </Button>
      <Button
        disabled
        component={Link}
        disableElevation
        to="/statistics"
        color="secondary"
        style={{ margin: '0 0 16px 16px' }}
      >
        {t('pages.videos.videoStats')}
      </Button>
      <VideoTable />
    </>
  );
};

export default Videos;
