import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles(
  (theme) => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    toolbar: {
      paddingRight: 24,
    },
    avatarLarge: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      marginBottom: theme.spacing(1),
    },
    firstListItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      '&:focus': {
        outline: 'none',
      },
    },
    logoutButton: {
      marginTop: theme.spacing(1),
      minWidth: 180,
    },
    menuContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
    },
  }),
  { name: 'HookGlobalStyles', index: 2 },
);

export default useStyles;
