import { createSlice } from '@reduxjs/toolkit';
import Logo from './assets/images/adflyer.png';

export const slice = createSlice({
  name: 'context',
  initialState: {
    theme: {
      primaryColorMain: '#353b48',
      secondaryColorMain: '#3E92CC',
      backgroundColorDefault: '#F0F2F5',
    },
    logo: Logo,
    localesUrl: undefined,
    apps: null,
    currentApp: 'adflyer',
  },
  reducers: {
    loadContext: (state, action) => {
      if (action.payload) {
        state.theme = action.payload.theme;
        state.apps = action.payload.apps;
        state.logo = action.payload.logo.main;
        state.localesUrl = action.payload.apps?.adflyer;
      }
    },
  },
});

export const selectContext = (state) => state.context;
export const { loadContext } = slice.actions;

export default slice.reducer;
