import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const Dialog = withStyles((theme) => ({
  paper: {
    minWidth: 320,
  },
}))(MuiDialog);

export default function ReusableDialog({
  title,
  open,
  cancel,
  children,
  accept,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id={`reusable-dialog`}>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={cancel}>
            {t('components.dialog.cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            onClick={accept}
          >
            {t('components.dialog.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ReusableDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  accept: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
};
