import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import UpdateLocation from '../../components/UpdateLocation';
import { siteMetadata } from '../../constants';

export default function UpdateLocationPage() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${t('general.updateLocation')} | ${
          siteMetadata.TITLE
        }`}</title>
      </Helmet>
      <Grid container>
        <Grid item xs={12} md={6}>
          <UpdateLocation />
        </Grid>
      </Grid>
    </>
  );
}
