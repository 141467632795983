import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeviceService from '../../services/DeviceService';
import DashboardCard from '../DashboardCard';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { toFormattedDate } from '../../utils/toFormattedDate';
import Skeleton from '@material-ui/lab/Skeleton';
import MuiDataTable from '../MuiDataTable';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../components/ConfirmDialog';
import PlaylistService from '../../services/PlaylistService';

const PlaylistsOnDevice = ({ deviceId }) => {
  const { t } = useTranslation();
  const [device, setDevice] = useState(null);
  const [playlists, setPlaylists] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDevice = DeviceService.get(deviceId);
    const getPlaylists = DeviceService.getPlaylists(deviceId);

    Promise.all([getDevice, getPlaylists])
      .then(([device, playlists]) => {
        setLoading(false);
        setDevice(device);
        setPlaylists(playlists);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [deviceId]);

  return (
    <>
      {loading ? (
        <Skeleton variant="rect" height={500} animation="wave" />
      ) : (
        <DashboardCard
          title={t('components.playlistsOnDevice.title')}
          minHeight="400px"
        >
          {!device?.activated ? (
            <div style={{ flexGrow: 1 }}>
              <Alert severity="info">
                {t('components.playlistsOnDevice.shouldActivated')}
              </Alert>
            </div>
          ) : (
            <>
              <div style={{ flexGrow: 1 }}>
                <Typography style={{ fontWeight: 600, marginBottom: 8 }}>
                  {t('components.playlistsOnDevice.actualPlaylist')}:
                </Typography>
                {device?.actualPlaylist ? (
                  <>
                    <ActualPlaylistTable device={device} />
                  </>
                ) : (
                  <Alert severity="warning">
                    {t('components.playlistsOnDevice.noActualPlaylist')}
                  </Alert>
                )}
                <hr style={{ margin: '1rem 0' }} />
                {/* {!playlists.length && (
                  <Alert severity="warning">
                    {t('components.playlistsOnDevice.noPlaylistsToBePlayed')}
                  </Alert>
                )} */}
                {Array.isArray(playlists) && (
                  <MorePlaylists
                    playlists={playlists}
                    setPlaylists={setPlaylists}
                  />
                )}
              </div>
            </>
          )}
          <Button
            component={Link}
            to={`/devices/new-playlist/${deviceId}`}
            variant="contained"
            color="secondary"
            style={{ alignSelf: 'flex-start', marginTop: '2rem' }}
            disabled={!device?.activated}
          >
            {t('components.playlistsOnDevice.addNewPlaylist')}
          </Button>
        </DashboardCard>
      )}
    </>
  );
};

PlaylistsOnDevice.propTypes = {
  deviceId: PropTypes.string.isRequired,
};

export default PlaylistsOnDevice;

function MorePlaylists({ playlists, setPlaylists }) {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [playlistIdToBeDeleted, setPlaylistIdToBeDeleted] = useState('');
  const [playlistNameToBeDeleted, setPlaylistNameToBeDeleted] = useState('');
  const columns = [
    t('components.morePlaylists.columns.startTime'),
    t('components.morePlaylists.columns.playlist'),
    t('components.morePlaylists.columns.actions'),
  ];

  function openDialog(playlistId, playlistName) {
    setIsDialogOpen(true);
    setPlaylistIdToBeDeleted(playlistId);
    setPlaylistNameToBeDeleted(playlistName);
  }

  function closeDialog() {
    setIsDialogOpen(false);
    setPlaylistIdToBeDeleted('');
    setPlaylistNameToBeDeleted('');
  }

  function deletePlaylist() {
    PlaylistService.remove(playlistIdToBeDeleted)
      .then((res) => {
        if (res.status === 204) {
          setIsDialogOpen(false);
          const updatedPlaylists = playlists.filter(
            (p) => p._id !== playlistIdToBeDeleted,
          );
          setPlaylists(updatedPlaylists);
        } else {
          setIsDialogOpen(false);
          throw new Error('something went wrong');
        }
      })
      .catch((err) => {
        console.error(err);
        setIsDialogOpen(false);
      });
  }

  const rows = playlists?.map((playlist, i) => [
    playlist ? toFormattedDate(playlist?.startTime) : `loading...`,
    playlist ? (
      <details key={playlist?._id}>
        <summary>{playlist?.nickname}</summary>
        <ol>
          {playlist?.videos.map((video, i) => (
            <li key={`${video._id}-${i}`}>{video.nickname}</li>
          ))}
        </ol>
      </details>
    ) : (
      `loading...`
    ),
    <>
      <div key={i}>
        <IconButton
          size="small"
          component={Link}
          to={`/playlists/update/${playlist._id}`}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          size="small"
          onClick={() => openDialog(playlist._id, playlist.nickname)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </>,
  ]);
  const title = t('components.morePlaylists.title');

  return (
    <>
      <MuiDataTable columns={columns} rows={rows} title={title} />
      <ConfirmDialog
        open={isDialogOpen}
        cancel={closeDialog}
        confirm={deletePlaylist}
        message={t('components.morePlaylists.dialog.confirmMessage', {
          playlistNameToBeDeleted,
        })}
        confirmButtonText={t('components.morePlaylists.dialog.confirmButton')}
      />
    </>
  );
}

MorePlaylists.propTypes = {
  playlists: PropTypes.array,
  setPlaylists: PropTypes.func,
};

// TODO: Refactor this component
function ActualPlaylistTable({ device }) {
  const { t } = useTranslation();

  return (
    <>
      {!device && <p>No device was found</p>}
      {device && (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          <div style={{ border: '1px solid', padding: 8 }}>
            {t('components.playlistsOnDevice.startTime')}
          </div>
          <div style={{ border: '1px solid', padding: 8 }}>
            {t('components.playlistsOnDevice.playlist')}
          </div>
          <div style={{ border: '1px solid', padding: 8 }}>
            {toFormattedDate(device.actualPlaylist?.startTime)}
          </div>
          <div style={{ border: '1px solid', padding: 8 }}>
            <details>
              <summary>{device.actualPlaylist.nickname}</summary>
              <ol>
                {device.actualPlaylist.videos.map((video, i) => (
                  <li key={`${video?._id}-${i}`}>{video?.nickname}</li>
                ))}
              </ol>
            </details>
          </div>
        </div>
      )}
    </>
  );
}

ActualPlaylistTable.propTypes = {
  device: PropTypes.object,
};
