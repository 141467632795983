import { useSelector, useDispatch } from 'react-redux';
import { selectIsLoggedIn, loadUser, logout } from '../userSlice';
import { useHistory } from 'react-router-dom';
import { emptyVideos } from '../components/VideoTable/videoSlice';
import { emptyDevices } from '../components/DeviceTable/deviceSlice';
import { emptyPlaylists } from '../components/PlaylistGrid/playlistSlice';
import jwtDecode from 'jwt-decode';

const useAuth = () => {
  const loggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  const history = useHistory();

  if (!loggedIn) {
    const token = window.localStorage.getItem('access-token');

    if (token) {
      try {
        const user = jwtDecode(token);
        const now = Math.floor(Date.now() / 1000);
        const isTokenValid = user.exp > now;

        if (!isTokenValid) {
          throw new Error();
        }

        dispatch(loadUser(user));
      } catch (e) {
        console.error('error in useAuth catch:', e);
        window.localStorage.removeItem('access-token');
      }
    } else {
      window.localStorage.removeItem('access-token');
    }
  }

  const logMeOut = () => {
    dispatch(logout());
    dispatch(emptyVideos());
    dispatch(emptyDevices());
    dispatch(emptyPlaylists());
    history.push('/');
  };

  return {
    loggedIn,
    logMeOut,
  };
};

export default useAuth;
