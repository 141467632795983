import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import MuiDataTable from '../MuiDataTable';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import DeviceService from '../../services/DeviceService';
import {
  selectDevices,
  selectIsDevicesLoaded,
  loadDevices,
} from './deviceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AiFillFolderOpen } from 'react-icons/ai';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import { getReadableId } from '../../utils/getReadableId';
import {
  NotActivatedIcon,
  OkIcon,
  StandbyIcon,
  WarningIcon,
  ErrorIcon,
} from '../DevicesWithProblems/DevicesWithProblems';
import { deviceStatuses } from '../../constants';

export const ActiveIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.success.main,
  },
}))(PlayCircleFilledIcon);

export const StandByIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.warning.light,
  },
}))(NightsStayIcon);

export default function DeviceTable() {
  const devices = useSelector(selectDevices);
  const isDevicesLoaded = useSelector(selectIsDevicesLoaded);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cDtC = 'components.deviceTable.columns';

  useEffect(() => {
    DeviceService.get()
      .then((devices) => {
        dispatch(loadDevices(devices));
      })
      .catch(console.error);
  }, [dispatch]);

  const columns = [
    `${t(`${cDtC}.name`)}`,
    `${t(`${cDtC}.details`)}`,
    `${t(`${cDtC}.id`)}`,
    `${t(`${cDtC}.type`)}`,
    `${t(`${cDtC}.location`)}`,
    // `${t(`${cDtC}.company`)}`,
    ``,
    `${t(`${cDtC}.status`)}`,
  ];

  function getStatusIcon(device) {
    if (device.status === deviceStatuses.OK) return <OkIcon />;
    if (device.status === deviceStatuses.STANDBY) return <StandbyIcon />;
    if (device.status === deviceStatuses.WARNING) return <WarningIcon />;
    if (device.status === deviceStatuses.ERROR) return <ErrorIcon />;
    if (device.status === deviceStatuses.NOT_ACTIVATED) {
      return <NotActivatedIcon />;
    } else {
      return <NotActivatedIcon />;
    }
  }

  const rows = (!isDevicesLoaded ? Array.from(new Array(3)) : devices).map(
    (dss, i) =>
      dss
        ? [
            dss.name,
            <IconButton
              key={i}
              component={Link}
              to={`/devices/details/${dss._id}`}
            >
              <AiFillFolderOpen
                style={{
                  fontSize: 28,
                }}
              />
            </IconButton>,
            getReadableId(dss.readableId),
            dss.type,
            dss.location?.address,
            // dss.company.name,
            getStatusIcon(dss),
            dss.status,
          ]
        : Array.from({ length: columns.length }, () => <Skeleton />),
  );

  return (
    <MuiDataTable
      rows={rows}
      columns={columns}
      title={t('components.deviceTable.title')}
    />
  );
}
