import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'sideMenu',
  initialState: {
    isSideMenuOpen: true,
    mobileBreakpoint: 768,
  },
  reducers: {
    closeMenu: (state) => {
      state.isSideMenuOpen = false;
    },
    openMenu: (state) => {
      state.isSideMenuOpen = true;
    },
  },
});

export const selectIsSideMenuOpen = (state) => state.sideMenu.isSideMenuOpen;
export const selectMobileBreakpoint = (state) =>
  state.sideMenu.mobileBreakpoint;
export const { openMenu, closeMenu } = slice.actions;

export default slice.reducer;
