import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Helmet } from 'react-helmet';
import capitalize from 'capitalize';
import LocationService from '../../services/LocationService';
import ConfirmDialog from '../../components/ConfirmDialog';
import LocationDetails from '../../components/LocationDetails';
import DevicesOnLocation from '../../components/DevicesOnLocation';
import DeniedAds from '../../components/DeniedAds';
import { loadAlert } from '../../userSlice';
import UpdateOpeningHours from '../../components/UpdateOpeningHours';
import { loadOpeningHours } from '../../components/LocationTable/locationSlice';
import { loadHoursFromLocation } from '../../components/OpeningHours/OpeningHours';

export const useLocation = () => {
  const { locationId } = useParams();
  const [location, setLocation] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    LocationService.get(locationId)
      .then((data) => {
        setLocation(data);
        dispatch(loadOpeningHours(loadHoursFromLocation(data)));
      })
      .catch(console.error);
  }, [locationId, dispatch]);

  return location;
};

export default function LocationDetailsPage() {
  const location = useLocation();
  const history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locationToBeDeleted = location?.name;
  const locationName = location?.name || t('general.loading');
  const pageName = capitalize(t('components.menuItems.locations'));

  function deleteLocation() {
    if (location._id) {
      LocationService.remove(location._id)
        .then((_) => {
          dispatch(
            loadAlert({
              type: 'info',
              message: 'Location was deleted.',
            }),
          );
          history.push(`/locations`);
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            loadAlert({
              message: err.message,
            }),
          );
        });
    }
  }

  return (
    <>
      <Helmet>
        <title>{`${locationName} | ${pageName}`}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <LocationDetails
            location={location}
            setIsDialogOpen={setIsDialogOpen}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DevicesOnLocation location={location} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DeniedAds location={location} />
        </Grid>
        <Grid item xs={12} md={6}>
          <UpdateOpeningHours location={location} />
        </Grid>
      </Grid>
      <ConfirmDialog
        open={isDialogOpen}
        cancel={() => setIsDialogOpen(false)}
        confirm={deleteLocation}
        message={`${t('pages.locationDetailsPage.confirmText', {
          locationToBeDeleted,
        })}`}
        confirmButtonText={`${t('pages.locationDetailsPage.buttons.confirm')}`}
      />
    </>
  );
}
