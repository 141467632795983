import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import DashboardCard from '../DashboardCard';
import DeviceService from '../../services/DeviceService';
import { getReadableFromMillisec } from '../../utils/getReadableFromMillisec';
import { toFormattedDate } from '../../utils/toFormattedDate';

export default function DeviceStatus({ deviceId }) {
  const { t } = useTranslation();
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const cDs = 'components.deviceStatus';

  useEffect(() => {
    DeviceService.getStatus(deviceId)
      .then((res) => {
        setStatus(res);
      })
      .catch((err) => {
        console.error(err);
        setError(err.message);
      });
  }, [deviceId]);

  return (
    <>
      <DashboardCard title={t(`${cDs}.title`)}>
        {error && <Alert severity="warning">{error}</Alert>}
        {!error && status && (
          <>
            <Typography gutterBottom variant="caption">
              {t(`${cDs}.time`)}:{' '}
              <strong>
                {status?.time ? toFormattedDate(status?.time) : 'N/A'}
              </strong>
            </Typography>
            <hr />
            <Typography gutterBottom>
              {t(`${cDs}.applicationState.title`)}:{' '}
              <strong>
                {status?.applicationState
                  ? t(
                      `${cDs}.applicationState.${status?.applicationState?.code}`,
                    )
                  : 'N/A'}
              </strong>
            </Typography>
            <Typography gutterBottom>
              {t(`${cDs}.videoPlayerState.title`)}:{' '}
              <strong>
                {status?.videoPlayerState
                  ? t(
                      `${cDs}.videoPlayerState.${status?.videoPlayerState?.code}`,
                    )
                  : 'N/A'}
              </strong>
            </Typography>
            <Typography gutterBottom>
              {t(`${cDs}.screenState.title`)}:{' '}
              <strong>
                {status?.screenState
                  ? t(`${cDs}.screenState.${status?.screenState?.code}`)
                  : 'N/A'}
              </strong>
            </Typography>
            <Typography gutterBottom>
              {t(`${cDs}.vpnIp`)}:{' '}
              <strong>{status?.vpnIp ? status?.vpnIp : 'N/A'}</strong>
            </Typography>
            <Typography gutterBottom>
              {t(`${cDs}.systemUptime`)}:{' '}
              <strong>{getReadableFromMillisec(status?.systemUptime)}</strong>
            </Typography>
            <Typography gutterBottom>
              {t(`${cDs}.applicationUptime`)}:{' '}
              <strong>
                {getReadableFromMillisec(status?.applicationUptime)}
              </strong>
            </Typography>
            <Typography gutterBottom>
              {t(`${cDs}.memory`)}: <strong>{status?.memory} %</strong>
            </Typography>
            <Typography gutterBottom>
              {t(`${cDs}.networkUsage`)}:{' '}
              <strong>
                {status?.networkUsage
                  ? bytesToSize(status?.networkUsage)
                  : 'N/A'}
              </strong>
            </Typography>
            <Typography gutterBottom>
              {t(`${cDs}.softwareVersion`)}:{' '}
              <strong>
                {status?.softwareVersion ? status?.softwareVersion : 'N/A'}
              </strong>
            </Typography>
          </>
        )}
      </DashboardCard>
    </>
  );
}

DeviceStatus.propTypes = {
  deviceId: PropTypes.string.isRequired,
};

// function getReadablePrecentage(precentageFloat) {
//   return `${precentageFloat.toFixed(2) * 100} %`;
// }

function bytesToSize(bytes) {
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb'];
  if (bytes == 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}
