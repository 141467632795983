import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minHeight: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    background: theme.palette.background.default,
  },
  gridContainer: {
    display: 'grid',
    gridTemplateRows: '1fr auto',
    minHeight: `calc(100vh - 120px)`,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  footer: {
    gridRowStart: 2,
    gridRowEnd: 3,
  },
  headerSpacer: theme.mixins.toolbar,
  root: {
    display: 'flex',
  },
}));

export default useStyles;
