import React from 'react';
import useStyles from './styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const CardTitle = (props) => {
  const classes = useStyles();

  return (
    <Typography
      data-testid="cardTitle"
      component="h2"
      variant="h6"
      gutterBottom
      display="inline"
      className={classes.font}
      style={{ margin: `${props.customMargin}` }}
    >
      {props.children}
    </Typography>
  );
};

CardTitle.propTypes = {
  children: PropTypes.string,
};

export default CardTitle;