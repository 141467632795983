import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const FormDialog = ({
  open,
  title,
  text,
  label,
  cancel,
  handleSend,
  fieldName,
  registerDetails,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation();

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id={`${fieldName}-dialog`}>{title}</DialogTitle>
        <form onSubmit={handleSubmit(handleSend)}>
          <DialogContent>
            <DialogContentText>{text}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id={fieldName}
              label={label}
              type="text"
              name={fieldName}
              fullWidth
              inputRef={register(registerDetails)}
              helperText={
                errors[`${fieldName}`] && errors[`${fieldName}`].message
              }
              error={Boolean(errors[`${fieldName}`])}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={cancel} color="primary">
              {t('components.formDialog.cancel')}
            </Button>
            <Button type="submit" color="primary">
              {t('components.formDialog.send')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  handleSend: PropTypes.func.isRequired,
  registerDetails: PropTypes.object,
};

export default FormDialog;
