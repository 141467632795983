import { createSlice } from '@reduxjs/toolkit';

const partnerSlice = createSlice({
  name: 'partners',
  initialState: {
    partners: [],
    isPartnersLoaded: false,
    partnerLoadingError: null,
  },
  reducers: {
    loadPartners: (state, action) => {
      if (action.payload === 'Not found') {
        state.partnerLoadingError =
          "Couldn't get any data from the server on that address";
        state.isPartnersLoaded = true;
      } else {
        state.partners = action.payload;
        state.isPartnersLoaded = true;
        state.partnerLoadingError = null;
      }
    },
    emptyPartners: (state, action) => {
      state.partners = [];
      state.isPartnersLoaded = false;
      state.partnerLoadingError = null;
    },
    catchError: (state, action) => {
      state.partnerLoadingError = action.payload;
      state.isPartnersLoaded = true;
    },
  },
});

export const selectPartners = (state) => state.partners.partners;
export const selectPartnerLoadingError = (state) =>
  state.partners.partnerLoadingError;
export const selectisPartnersLoaded = (state) =>
  state.partners.isPartnersLoaded;

export const { loadPartners, catchError, emptyPartners } = partnerSlice.actions;

export default partnerSlice.reducer;
