import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MuiDataTable from '../MuiDataTable';
import Skeleton from '@material-ui/lab/Skeleton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { AiFillFolderOpen } from 'react-icons/ai';
import LocationService from '../../services/LocationService';
import {
  selectIsLocationsLoaded,
  loadLocations,
  selectLocations,
} from './locationSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function LocationTable() {
  const { t } = useTranslation();
  const locations = useSelector(selectLocations);
  const isLocationsLoaded = useSelector(selectIsLocationsLoaded);
  const dispatch = useDispatch();

  useEffect(() => {
    LocationService.get()
      .then((locations) => {
        dispatch(loadLocations(locations));
      })
      .catch(console.error);
  }, [dispatch]);

  const columns = [
    `${t('components.locationTable.columns.name')}`,
    `${t('components.locationTable.columns.details')}`,
    `${t('components.locationTable.columns.address')}`,
    `${t('components.locationTable.columns.activity')}`,
    `${t('components.locationTable.columns.actions')}`,
  ];

  const rows = (!isLocationsLoaded ? Array.from(new Array(3)) : locations).map(
    (location, i) =>
      location
        ? [
            location.name,
            <IconButton
              key={i}
              component={Link}
              to={`/locations/details/${location._id}`}
            >
              <AiFillFolderOpen
                style={{
                  fontSize: 28,
                }}
              />
            </IconButton>,
            location.address,
            location.activity,
            <>
              <Tooltip title={t('components.locationTable.tooltips.edit')}>
                <IconButton
                  size="small"
                  style={{ marginRight: 8 }}
                  component={Link}
                  to={`/locations/update/${location._id}`}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>,
          ]
        : Array.from({ length: columns.length }, () => <Skeleton />),
  );

  return (
    <>
      <MuiDataTable
        rows={rows}
        columns={columns}
        title={t('components.locationTable.title')}
      />
    </>
  );
}
