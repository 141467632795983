import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  select: {
    background: 'white',
    padding: 5,
  },
}));

export default useStyles;
