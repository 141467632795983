import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import capitalize from 'capitalize';
import { useTranslation } from 'react-i18next';
import PartnerDetails from '../../components/PartnerDetails/PartnerDetails';
import PartnerService from '../../services/PartnerService';

export default function PartnerDetailsPage() {
  const { partnerId } = useParams();
  const [partner, setPartner] = useState({});
  const { t } = useTranslation();
  const partnerName = partner?.name || t('general.loading');
  const pageName = capitalize(t('components.menuItems.partners'));

  useEffect(() => {
    PartnerService.get(partnerId)
      .then((partner) => {
        setPartner(partner);
      })
      .catch(console.error);
  }, [partnerId]);

  return (
    <>
      <Helmet>
        <title>{`${partnerName} | ${pageName}`}</title>
      </Helmet>
      {!partner && `Loading...`}
      {partner._id && <PartnerDetails partner={partner} />}
    </>
  );
}
