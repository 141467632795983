export default {
  baseUrlDss:
    process.env.NODE_ENV === 'development'
      ? 'https://staging-dss-backend-fuve.herokuapp.com/api/v1/dss-backend'
      : 'https://dss-backend-fuve.herokuapp.com/api/v1/dss-backend',
  baseUrlAuth:
    process.env.NODE_ENV === 'development'
      ? 'https://staging-fuve-auth-server.herokuapp.com/api/v1/fuve-auth-server'
      : 'https://fuve-auth-server.herokuapp.com/api/v1/fuve-auth-server',
};
