import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { AiFillFolderOpen } from 'react-icons/ai';
import MuiDataTable from '../MuiDataTable';
import PartnerService from '../../services/PartnerService';
import {
  loadPartners,
  selectPartners,
  selectisPartnersLoaded,
} from './partnerSlice';
import ConfirmDialog from '../ConfirmDialog';
import { loadAlert } from '../../userSlice';

export default function PartnerTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [partnerIdToBeDeleted, setPartnerIdToBeDeleted] = useState('');
  const [partnerToBeDeleted, setPartnerToBeDeleted] = useState('');
  const partners = useSelector(selectPartners);
  const isPartnersLoaded = useSelector(selectisPartnersLoaded);
  const cpTC = 'components.partnerTable.columns';

  const columns = [
    `${t(`${cpTC}.name`)}`,
    `${t(`${cpTC}.details`)}`,
    `${t(`${cpTC}.contactPerson`)}`,
    `${t(`${cpTC}.phone`)}`,
    `${t(`${cpTC}.email`)}`,
    `${t(`${cpTC}.actions`)}`,
  ];

  const rows = (!isPartnersLoaded ? Array.from(new Array(3)) : partners).map(
    (partner, i) =>
      partner
        ? [
            partner.name,
            <IconButton
              key={i}
              component={Link}
              to={`/partners/details/${partner._id}`}
            >
              <AiFillFolderOpen
                style={{
                  fontSize: 24,
                }}
              />
            </IconButton>,
            partner.contactPerson,
            partner.phone,
            partner.email,
            <>
              <Tooltip title={t('components.partnerTable.tooltips.edit')}>
                <IconButton
                  size="small"
                  style={{ marginRight: 8 }}
                  component={Link}
                  to={`/partners/update/${partner._id}`}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('components.partnerTable.tooltips.delete')}>
                <IconButton
                  size="small"
                  style={{ marginRight: 8 }}
                  onClick={() => handleDelete(partner._id, partner.name)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>,
          ]
        : Array.from({ length: columns.length }, () => <Skeleton />),
  );

  useEffect(() => {
    PartnerService.get()
      .then((partners) => {
        dispatch(loadPartners(partners));
      })
      .catch(console.error);
  }, [dispatch]);

  function deletePartner() {
    PartnerService.remove(partnerIdToBeDeleted)
      .then((res) => {
        if (res.status === 204) {
          setIsDialogOpen(false);
          const updatedPartners = partners.filter(
            (p) => p._id !== partnerIdToBeDeleted,
          );
          dispatch(loadPartners(updatedPartners));
          // TODO: add toast notification
          dispatch(
            loadAlert({
              type: 'info',
              message: t('components.partnerTable.alerts.successfulDeletion'),
            }),
          );
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          loadAlert({
            type: 'error',
            message: t('components.partnerTable.alerts.error'),
          }),
        );
      });
  }

  function handleDelete(partnerId, partnerName) {
    setIsDialogOpen(true);
    setPartnerIdToBeDeleted(partnerId);
    setPartnerToBeDeleted(partnerName);
  }

  function cancelDelete() {
    setIsDialogOpen(false);
    setPartnerIdToBeDeleted('');
    setPartnerToBeDeleted('');
  }

  return (
    <>
      <MuiDataTable
        rows={rows}
        columns={columns}
        title={t('components.partnerTable.title')}
      />
      <ConfirmDialog
        open={isDialogOpen}
        cancel={cancelDelete}
        confirm={deletePartner}
        message={t('components.partnerTable.dialog.confirmDelete', {
          partnerToBeDeleted,
        })}
        confirmButtonText={t('components.partnerTable.dialog.buttons.confirm')}
      />
    </>
  );
}
