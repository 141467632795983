import HttpClient from '../utils/httpClient';
import config from '../config';

let httpClient = new HttpClient(config.baseUrlDss);

export default {
  get,
  update,
  create,
  remove,
};

async function get(partnerId = '') {
  return await httpClient.get(`/partners/${partnerId}`);
}

async function update(partnerId = '', requestBody) {
  return await httpClient.put(`/partners/update/${partnerId}`, requestBody);
}

async function create(requestBody) {
  return await httpClient.post(`/partners/new`, requestBody);
}

async function remove(partnerId = '') {
  return await httpClient.delete(`/partners/delete/${partnerId}`);
}
