export function getReadableFromMillisec(millisec) {
  const sec = millisec / 1000;
  if (sec > 3599) {
    const h = Math.floor(sec / 3600);
    const m = Math.floor((sec - h * 3600) / 60);
    const s = sec % 60;
    return `${h}h ${m}m ${s.toFixed(0)}s`;
  } else if (sec > 59) {
    const m = Math.floor(sec / 60);
    const s = sec % 60;
    return `${m}m ${s.toFixed(0)}s`;
  }
  return `${sec.toFixed(0)}s`;
}
