import React, { useEffect } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@material-ui/styles';
import useAuth from './utils/useAuth';
import LoginPage from './pages/Login';
import Routing from './Routing';
import { selectLoggedInUser } from './userSlice';
import { loadContext } from './contextSlice';
import useTheme from './utils/useTheme';

const App = () => {
  const { loggedIn } = useAuth();
  const { language } = useSelector(selectLoggedInUser);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    if (language !== undefined) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  dispatch(loadContext(window.appConfig));

  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <ThemeProvider theme={theme}>
        {!loggedIn ? <LoginPage /> : <Routing />}
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
