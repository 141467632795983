import { createSlice } from '@reduxjs/toolkit';

const locationSlice = createSlice({
  name: 'locations',
  initialState: {
    locations: [],
    isLocationsLoaded: false,
    locationLoadingError: null,
    openingHours: null,
  },
  reducers: {
    loadLocations: (state, action) => {
      if (action.payload === 'Not found') {
        state.locationLoadingError =
          "Couldn't get any data from the server on that address";
        state.isLocationsLoaded = true;
      } else {
        state.locations = action.payload;
        state.isLocationsLoaded = true;
        state.locationLoadingError = null;
      }
    },
    emptyLocations: (state, action) => {
      state.locations = [];
      state.isLocationsLoaded = false;
      state.locationLoadingError = null;
      state.openingHours = null;
    },
    loadOpeningHours: (state, action) => {
      state.openingHours = action.payload;
    },
    catchError: (state, action) => {
      state.locationLoadingError = action.payload;
      state.isLocationsLoaded = true;
    },
  },
});

export const selectLocations = (state) => state.locations.locations;
export const selectLocationLoadingError = (state) =>
  state.locations.locationLoadingError;
export const selectIsLocationsLoaded = (state) =>
  state.locations.isLocationsLoaded;
export const selectOpeningHours = (state) => state.locations.openingHours;

export const { loadLocations, catchError, emptyLocations, loadOpeningHours } =
  locationSlice.actions;

export default locationSlice.reducer;
