import { createSlice } from '@reduxjs/toolkit';

const deviceSlice = createSlice({
  name: 'devices',
  initialState: {
    devices: [],
    isDevicesLoaded: false,
    deviceLoadingError: null,
  },
  reducers: {
    loadDevices: (state, action) => {
      if (action.payload === 'Not found') {
        state.deviceLoadingError =
          "Couldn't get any data from the server on that address";
        state.isDevicesLoaded = true;
      } else {
        state.devices = action.payload;
        state.isDevicesLoaded = true;
        state.deviceLoadingError = null;
      }
    },
    emptyDevices: (state, action) => {
      state.devices = [];
      state.isDevicesLoaded = false;
      state.deviceLoadingError = null;
    },
    catchError: (state, action) => {
      state.deviceLoadingError = action.payload;
      state.isDevicesLoaded = true;
    },
  },
});

export const selectDevices = (state) => state.devices.devices;
export const selectDeviceLoadingError = (state) =>
  state.devices.deviceLoadingError;
export const selectIsDevicesLoaded = (state) => state.devices.isDevicesLoaded;

export const { loadDevices, catchError, emptyDevices } = deviceSlice.actions;

export default deviceSlice.reducer;
