import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'user',
  initialState: {
    isLoggedIn: false,
    loggedInUser: {},
    alertMessage: null,
  },
  reducers: {
    loadUser: (state, action) => {
      state.loggedInUser = action.payload;
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.loggedInUser = {};
      state.isLoggedIn = false;
      state.alertMessage = null;
      localStorage.removeItem('access-token');
    },
    loadAlert: (state, action) => {
      state.alertMessage = action.payload;
    },
    emptyAlert: (state) => {
      state.alertMessage = null;
    },
    changeLanguage: (state, action) => {
      if (state.loggedInUser) {
        state.loggedInUser.language = action.payload;
      }
    },
  },
});

export const selectIsLoggedIn = (state) => state.user.isLoggedIn;
export const selectLoggedInUser = (state) => state.user.loggedInUser;
export const selectAlertMessage = (state) => state.user.alertMessage;
export const {
  loadUser,
  logout,
  loadAlert,
  emptyAlert,
  changeLanguage,
} = slice.actions;

export default slice.reducer;
