import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  (theme) => ({
    '@global': {
      body: {
        background: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
          background: theme.palette.background.paper,
        },
      },
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    logo: {
      margin: theme.spacing(1),
      width: '40%',
    },
    paper: {
      marginTop: theme.spacing(8),
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        boxShadow: 'none',
        marginTop: theme.spacing(2),
      },
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      cursor: 'pointer',
    },
  }),
  { name: 'HookGlobalStyles', index: 2 },
);

export default useStyles;
