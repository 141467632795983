import React from 'react';
import useStyles from './styles';
import Paper from '@material-ui/core/Paper';
import CardTitle from '../CardTitle';

const Card = ({ children, fixedHeight, title, minHeight, position }) => {
  const classes = useStyles();

  return (
    <Paper
      className={classes.paper}
      style={{ height: fixedHeight, minHeight, position }}
    >
      <CardTitle customMargin="0 0 1rem 0">{title}</CardTitle>
      {children}
    </Paper>
  );
};

export default Card;
