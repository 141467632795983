import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import DashboardCard from '../DashboardCard';
import InputField from '../InputField';
import PartnerService from '../../services/PartnerService';
import { loadAlert } from '../../userSlice';
import fields from './fields';

export default function CreatePartner() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { handleSubmit, register, errors } = useForm();

  function onSubmit(data) {
    // ? Just for random square logos for dev
    const logos = ['13', '14', '15', '16', '19', '21', '24'];
    const random = Math.floor(Math.random() * 7);

    const reqBody = {
      ...data,
      // ? Placeholder logo image
      // logo: `https://logoipsum.com/logo/logo-${logos[random]}.svg`,
    };

    PartnerService.create(reqBody)
      .then((newPartner) => {
        console.log(newPartner.status);
        dispatch(
          loadAlert({
            type: 'success',
            message: `Partner "${newPartner.name}" was created.`,
          }),
        );
        history.push('/partners');
      })
      .catch(console.error);
  }

  return (
    <>
      <DashboardCard title={t('components.createPartner.title')}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          {fields.map((field) => (
            <InputField
              key={field.name}
              label={t(`components.createPartner.form.${field.name}.label`)}
              name={field.name}
              placeholder={t(
                `components.createPartner.form.${field.name}.placeholder`,
              )}
              inputRef={register({
                required:
                  field.required &&
                  `${t(
                    `components.createPartner.form.${field.name}.required`,
                  )}`,
                pattern: field.pattern,
              })}
              error={Boolean(errors[`${field.name}`])}
              helperText={
                errors[`${field.name}`] && errors[`${field.name}`].message
              }
            />
          ))}
          <div style={{ marginTop: '2rem' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
              style={{ marginRight: '1rem' }}
            >
              {t('components.createPartner.buttons.create')}
            </Button>
            <Button
              type="button"
              component={Link}
              to={`/partners`}
              variant="contained"
              disableElevation
            >
              {t('components.createPartner.buttons.cancel')}
            </Button>
          </div>
        </form>
      </DashboardCard>
    </>
  );
}
