import React, { useState, useEffect } from 'react';
import useStyles from './styles';

// import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

// import Copyright from '../Copyright';
import Header from '../Header';
import SideMenu from '../SideMenu';

import { useSelector, useDispatch } from 'react-redux';
import { selectAlertMessage } from '../../userSlice';
import AlertBar from '../AlertBar';
import useViewport from '../../utils/useViewport';

import {
  openMenu,
  closeMenu,
  selectMobileBreakpoint,
} from '../SideMenu/sideMenuSlice';

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const mobileBreakpoint = useSelector(selectMobileBreakpoint);
  const alertMessage = useSelector(selectAlertMessage);
  const [alert, setAlert] = useState(alertMessage);

  useEffect(() => {
    setAlert(alertMessage);
  }, [alertMessage]);

  const { width } = useViewport();

  useEffect(() => {
    if (width > mobileBreakpoint) {
      dispatch(openMenu());
    } else {
      dispatch(closeMenu());
    }
  }, [width, dispatch, mobileBreakpoint]);

  return (
    <div className={classes.root}>
      {alert ? <AlertBar message={alert.message} type={alert.type} /> : null}
      <CssBaseline />
      <Header />
      <SideMenu />
      <main className={classes.content}>
        <div className={classes.headerSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.gridContainer}>
            <div>{children}</div>
            {/* Temporary removed */}
            {/* <Box pt={4} className={classes.footer}>
              <Copyright />
            </Box> */}
          </div>
        </Container>
      </main>
    </div>
  );
};

export default Layout;
