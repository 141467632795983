import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import DashboardCard from '../DashboardCard';
import PartnerService from '../../services/PartnerService';
import BusinessCenterRoundedIcon from '@material-ui/icons/BusinessCenterRounded';

export default function PartnersList() {
  const [partners, setPartners] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    PartnerService.get()
      .then((partners) => {
        setPartners(partners);
      })
      .catch(console.error);
  }, []);

  return (
    <>
      <DashboardCard title={t('components.partnersList.title')}>
        <List>
          {!partners.length ? (
            <ListItem>
              <ListItemAvatar>
                <Skeleton variant="circle" width={40} height={40} />
              </ListItemAvatar>
              <ListItemText primary={<Skeleton />} />
            </ListItem>
          ) : (
            <ListItem button component={Link} to={`/partners`}>
              <ListItemAvatar>
                <Avatar>
                  <BusinessCenterRoundedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${partners.length} ${t(
                  'components.partnersList.partner',
                )}`}
              />
            </ListItem>
          )}
          {/* {(!partners.length ? Array.from(new Array(2)) : partners).map(
            (partner, i) => (
              <ListItem
                key={partner?._id || i}
                button
                component={Link}
                to={`/partners/details/${partner?._id}`}
              >
                <ListItemAvatar>
                  {!partner ? (
                    <Skeleton variant="circle" width={40} height={40} />
                  ) : (
                    <Avatar
                      src={partner?.logo}
                      alt={`logo of ${partner?.name}`}
                    >
                      {partner?.name.charAt(0).toUpperCase()}
                    </Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText primary={partner?.name || <Skeleton />} />
              </ListItem>
            ),
          )} */}
        </List>
      </DashboardCard>
    </>
  );
}
