import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';

import VideoService from '../../services/VideoService';
import CardTitle from '../../components/CardTitle';
import ProgressBackdrop from '../../components/ProgressBackdrop';
import LinearProgressWithLabel from '../../components/LinearProgressWithLabel';
import capitalize from 'capitalize';
import { useTranslation } from 'react-i18next';

const UploadUpdateVideo = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let history = useHistory();
  const { videoId } = useParams();

  const [nickname, setNickname] = useState('');
  const [description, setDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedFileName, setSelectedFileName] = useState(
    `${t('pages.uploadUpdateVideo.textLabels.noFileChosen')}`,
  );
  const [uploadedPercentage, setUploadedPercentage] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [fileTypeError, setFileTypeError] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const pageName = capitalize(t('components.menuItems.videos'));
  const videoName = nickname || t('general.loading');

  useEffect(() => {
    if (videoId) {
      setLoading(true);
      VideoService.get(videoId)
        .then((data) => {
          setLoading(false);
          setNickname(data.nickname);
          setDescription(data.description);
        })
        .catch((err) => {
          setErrorMessage(
            `Video with ID "${videoId}" was not found in the database. Please try again.`,
          );
        });
    }
  }, [videoId]);

  const handleNameChange = (e) => {
    setNickname(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const isSubmitDisabled = () => {
    if (videoId) {
      return !nickname || !description;
    } else {
      return !nickname || !description || !selectedFile || uploading;
    }
  };

  const onChange = (e) => {
    if (e.target.files[0] === undefined) {
      setSelectedFileName(
        `${t('pages.uploadUpdateVideo.textLabels.noFileChosen')}`,
      );
    } else if (!e.target.files[0].type.includes('mp4')) {
      const videoType = e.target.files[0].type;
      setFileTypeError(`${t('pages.uploadUpdateVideo.notMp4', { videoType })}`);
    } else {
      setSelectedFile(e.target.files[0]);
      setSelectedFileName(e.target.files[0].name);
    }
  };

  const handleSubmit = () => {
    if (videoId) {
      // UPDATE A VIDEO
      setUploading(true);

      const reqBody = {
        nickname,
        description,
      };
      VideoService.updateVideo(videoId, reqBody)
        .then((res) => {
          setUploading(false);
          history.push('/videos');
        })
        .catch(console.error);
    } else {
      // UPLOAD NEW VIDEO
      const formData = new FormData();
      formData.append('videoFile', selectedFile);
      formData.append('nickname', nickname);
      formData.append('description', description);

      setUploading(true);

      VideoService.fileUpload(formData, setUploadedPercentage)
        .then((res) => {
          // TODO: visszakérni valami választ, és kiírni valamit a usernek belőle.
          console.log('fajl feltoltes valasz: ', res);

          setUploading(false);
          history.push('/videos');
        })
        .catch((err) => {
          setUploading(false);
          console.error('video feltoltes sikertelen: ', err);
          setErrorMessage(
            `Something went wrong. Probably missed to attach a video file. Please try again.`,
          );
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${videoId ? t('general.update') : t('general.upload')} | ${
          videoId ? videoName : pageName
        }`}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {errorMessage ? (
            <>
              <Alert severity="error">{errorMessage}</Alert>
              <Button
                variant="contained"
                component={Link}
                to="/videos"
                className={classes.backButton}
              >
                Back
              </Button>
            </>
          ) : (
            <Paper className={classes.paper}>
              <CardTitle>
                {videoId
                  ? `${t(`pages.uploadUpdateVideo.title.update`, { nickname })}`
                  : `${t(`pages.uploadUpdateVideo.title.upload`)}`}
              </CardTitle>
              <TextField
                margin="normal"
                fullWidth
                label={t('pages.uploadUpdateVideo.textLabels.name')}
                variant="outlined"
                value={loading ? 'loading...' : nickname}
                onChange={handleNameChange}
                autoFocus
              />
              <TextField
                margin="normal"
                fullWidth
                label={t('pages.uploadUpdateVideo.textLabels.description')}
                variant="outlined"
                multiline
                value={loading ? 'loading...' : description}
                onChange={handleDescriptionChange}
              />
              {videoId ? null : (
                <div>
                  <LinearProgressWithLabel value={uploadedPercentage} />
                  <div className={classes.fileBrowser}>
                    <Tooltip title={selectedFileName}>
                      <Typography className={classes.fileName}>
                        {selectedFileName}
                      </Typography>
                    </Tooltip>
                    <input
                      type="file"
                      accept="video/*"
                      id="video-upload"
                      className={classes.input}
                      onChange={onChange}
                    />
                    <Button
                      disableElevation
                      htmlFor="video-upload"
                      component="label"
                      variant="contained"
                      onClick={() => setFileTypeError('')}
                    >
                      {t('pages.uploadUpdateVideo.textLabels.browse')}
                    </Button>
                  </div>
                  {fileTypeError ? (
                    <Alert severity="error">{fileTypeError}</Alert>
                  ) : null}
                </div>
              )}
              <div className={classes.buttonSection}>
                <Button component={Link} to="/videos">
                  {t('pages.uploadUpdateVideo.textLabels.cancel')}
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitDisabled()}
                >
                  {videoId
                    ? `${t('pages.uploadUpdateVideo.textLabels.save')}`
                    : `${t('pages.uploadUpdateVideo.textLabels.upload')}`}
                </Button>
              </div>
            </Paper>
          )}
        </Grid>
        <ProgressBackdrop open={uploading} />
      </Grid>
    </>
  );
};

export default UploadUpdateVideo;
