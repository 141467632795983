import { configureStore } from '@reduxjs/toolkit';
import sideMenuReducer from './components/SideMenu/sideMenuSlice';
// import salesReducer from './components/SalesTable/salesTableSlice';
import videosReducer from './components/VideoTable/videoSlice';
import userReducer from './userSlice';
// import deviceReducer from './components/DeviceTable/deviceSlice';
import playlistReducer from './components/PlaylistGrid/playlistSlice';
// import productReducer from './components/ProductsTable/productSlice';
import deviceReducer from './components/DeviceTable/deviceSlice';
import contextReducer from './contextSlice';
// import vendingMachineReducer from './components/VendingMachinesTable/vendingMachineSlice';
import locationReducer from './components/LocationTable/locationSlice';
import partnerReducer from './components/PartnerTable/partnerSlice';

export default configureStore({
  reducer: {
    sideMenu: sideMenuReducer,
    // salesStat: salesReducer,
    user: userReducer,
    // device: deviceReducer,
    videosList: videosReducer,
    playlists: playlistReducer,
    // product: productReducer,
    devices: deviceReducer,
    // vendingMachines: vendingMachineReducer,
    context: contextReducer,
    locations: locationReducer,
    partners: partnerReducer,
  },
});
