import React from 'react';
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import DevicesPage from './pages/Devices';
import CreateDevicePage from './pages/CreateDevice';
// import PlaylistsPage from './pages/Playlists';
import VideosPage from './pages/Videos';
import CreatePlaylistPage from './pages/CreatePlaylist';
import UploadUpdateVideoPage from './pages/UploadUpdateVideo';
import DeviceDetailsPage from './pages/DeviceDetailsPage';
// import StatisticsPage from './pages/Statistics';
import UserSettingsPage from './pages/UserSettings';
import VideoDetailedPage from './pages/VideoDetails';
import LocationsPage from './pages/Locations';
import CreateLocationPage from './pages/CreateLocationPage';
import LocationDetailsPage from './pages/LocationDetailsPage';
import UpdateLocationPage from './pages/UpdateLocationPage';
// import UpdateDevicePage from './pages/UpdateDevice';
import UpdatePlaylistPage from './pages/UpdatePlaylistPage';
import Layout from './components/Layout';
import PartnersPage from './pages/PartnersPage';
import CreatePartnerPage from './pages/CreatePartnerPage';
import UpdatePartnerPage from './pages/UpdatePartnerPage';
import PartnerDetailsPage from './pages/PartnerDetailsPage';
import DeviceEventLogPage from './pages/DeviceEventLogPage';
import DevicePlaylistsPage from './pages/DevicePlaylistsPage';

const Routing = () => {
  return (
    <Router basename="/adflyer">
      <Switch>
        <Layout>
          <Route path={`/locations/update/:locationId`}>
            <UpdateLocationPage />
          </Route>
          <Route path={`/locations/details/:locationId`}>
            <LocationDetailsPage />
          </Route>
          <Route path={`/locations/new`}>
            <CreateLocationPage />
          </Route>
          <Route exact path={`/locations`}>
            <LocationsPage />
          </Route>
          <Route path={`/settings`}>
            <UserSettingsPage />
          </Route>
          <Route path={`/videos/details/:videoId`}>
            <VideoDetailedPage />
          </Route>
          <Route path={`/videos/update/:videoId`}>
            <UploadUpdateVideoPage />
          </Route>
          <Route path={`/videos/upload`}>
            <UploadUpdateVideoPage />
          </Route>
          <Route exact path={`/videos`}>
            <VideosPage />
          </Route>
          <Route exact path={`/playlists/update/:playlistId`}>
            <UpdatePlaylistPage />
          </Route>
          <Route exact path={`/playlists`}>
            {/* <PlaylistsPage /> */}
            <Redirect to={`/dashboard`} />
          </Route>
          <Route path={`/devices/new-playlist/:deviceId`}>
            <CreatePlaylistPage />
          </Route>
          {/* <Route path={`/devices/update/:deviceId`}>
            <UpdateDevicePage />
          </Route> */}
          <Route path={`/devices/event-log/:deviceId`}>
            <DeviceEventLogPage />
          </Route>
          <Route path={`/devices/playlists/:deviceId`}>
            <DevicePlaylistsPage />
          </Route>
          <Route path={`/devices/details/:deviceId`}>
            <DeviceDetailsPage />
          </Route>
          <Route path={`/devices/new`}>
            <CreateDevicePage />
          </Route>
          <Route exact path={`/devices`}>
            <DevicesPage />
          </Route>
          <Route exact path={`/partners/details/:partnerId`}>
            <PartnerDetailsPage />
          </Route>
          <Route exact path={`/partners/update/:partnerId`}>
            <UpdatePartnerPage />
          </Route>
          <Route exact path={`/partners/new`}>
            <CreatePartnerPage />
          </Route>
          <Route exact path={`/partners`}>
            <PartnersPage />
          </Route>
          <Route exact path={`/statistics`}>
            {/* <StatisticsPage /> */}
            <Redirect to={`/dashboard`} />
          </Route>
          <Route exact path={`/dashboard`}>
            <DashboardPage />
          </Route>
          <Route exact path="/">
            <Redirect to={`/dashboard`} />
          </Route>
        </Layout>
      </Switch>
    </Router>
  );
};

export default Routing;
