import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const ConfirmDialog = ({
  open,
  cancel,
  confirm,
  message,
  confirmButtonText,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} data-testid="confirm-dialog">
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disableElevation onClick={cancel} color="primary">
          {t('components.confirmDialog.buttons.cancel')}
        </Button>
        <Button
          disableElevation
          onClick={confirm}
          color="primary"
          variant="contained"
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
};

export default ConfirmDialog;
