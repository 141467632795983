import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import DashboardCard from '../DashboardCard';

export default function LocationDetails({ location, setIsDialogOpen }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <DashboardCard title={location?.name} minHeight={400}>
        <div>
          <Typography>{location?.activity}</Typography>
          <Typography>{location?.address}</Typography>
        </div>
        <div className={classes.actions}>
          <DangerButton
            disableElevation
            variant="contained"
            onClick={() => setIsDialogOpen(true)}
          >
            {t('components.locationDetails.buttons.delete')}
          </DangerButton>
          <Button
            disableElevation
            variant="contained"
            component={Link}
            to={`/locations`}
          >
            {t('components.locationDetails.buttons.back')}
          </Button>
        </div>
      </DashboardCard>
    </>
  );
}

LocationDetails.propTypes = {
  location: PropTypes.object,
  setIsDialogOpen: PropTypes.func.isRequired,
};

const DangerButton = withStyles((theme) => ({
  contained: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
}))(Button);
