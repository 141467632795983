import HttpClient from '../utils/httpClient';
import config from '../config';

let httpClient = new HttpClient(config.baseUrlDss);

export default {
  get,
  create,
  update,
  remove,
};

async function get(playlistId = '') {
  return await httpClient.get(`/playlists/${playlistId}`);
}

async function create(reqBody) {
  return await httpClient.post(`/playlists/new`, reqBody);
}

async function update(playlistId, reqBody) {
  return await httpClient.put(`/playlists/update/${playlistId}`, reqBody);
}

async function remove(playlistId) {
  return await httpClient.delete(`/playlists/delete/${playlistId}`);
}
