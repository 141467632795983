import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import 'regenerator-runtime/runtime.js';
import store from './store';
import { Provider } from 'react-redux';
import App from './App';
import ProgressBackdrop from './components/ProgressBackdrop';

window.renderAdFlyer = (containerId, history) => {
  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<ProgressBackdrop open={true} />}>
        <App history={history} />
      </Suspense>
    </Provider>,
    document.getElementById(containerId),
  );
};

window.unmountAdflyer = (containerId) => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
};
