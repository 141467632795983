import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const SaveButton = ({ saving = false, editState = false, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          disabled={saving || !editState}
          onClick={onClick}
        >
          {editState
            ? `${t('components.saveButton.buttonTextSave')}`
            : `${t('components.saveButton.buttonTextSaved')}`}
        </Button>
        {saving && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
};

SaveButton.propTypes = {
  saving: PropTypes.bool,
  editState: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SaveButton;
