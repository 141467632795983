import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import DevicePageNav from '../../components/DevicePageNav';
import PlaylistsOnDevice from '../../components/PlaylistsOnDevice';
import { useDevice } from '../../utils/useDevice';

export default function DevicePlaylistsPage() {
  const { deviceId } = useParams();
  const device = useDevice(deviceId);
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${device?.name || t('general.loading')} | Playlists`}</title>
      </Helmet>
      <DevicePageNav deviceId={deviceId} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PlaylistsOnDevice deviceId={deviceId} />
        </Grid>
      </Grid>
    </>
  );
}
