import { createMuiTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { selectContext } from '../contextSlice';

const useTheme = () => {
  const { theme } = useSelector(selectContext);

  return createMuiTheme({
    palette: {
      primary: {
        main: theme.primaryColorMain,
      },
      secondary: {
        main: theme.secondaryColorMain,
      },
      background: {
        default: theme.backgroundColorDefault,
      },
    },
  });
};

export default useTheme;
