import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Autocomplete,
  LoadScript,
  Marker,
  GoogleMap,
} from '@react-google-maps/api';
import DashboardCard from '../DashboardCard';
import InputField from '../InputField';
import LocationService from '../../services/LocationService';
import PartnerService from '../../services/PartnerService';
import { getAddress } from '../CreateLocation/getAddress';
import { TextField } from '@material-ui/core';
import { loadAlert } from '../../userSlice';
import DeniedAdsInput from '../DeniedAdsInput';

const libraries = ['places'];
const fields = ['place_id', 'geometry', 'name', 'address_component'];
const componentRestrictions = { country: ['HU'] };

export default function LocationUpdate() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleSubmit, register, errors } = useForm();
  const { locationId } = useParams();
  const [location, setLocation] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [isAddressInvalid, setIsAddressInvalid] = useState(false);
  const [address, setAddress] = useState(``);
  const [longitude, setLongitude] = useState(``);
  const [latitude, setLatitude] = useState(``);
  const [existingPartner, setExistingPartner] = useState(``);
  const [partners, setPartners] = useState([]);
  const [inputValue, setInputValue] = useState(``);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const locationName = location?.name;
  const cUlIf = 'components.updateLocation.inputFields';
  const [deniedAds, setDeniedAds] = useState([]);

  useEffect(() => {
    const partnerQuery = PartnerService.get();
    const locationQuery = LocationService.get(locationId);
    Promise.all([partnerQuery, locationQuery])
      .then(([partners, location]) => {
        setLocation(location);
        setLongitude(location.coordinates[0]);
        setLatitude(location.coordinates[1]);
        setAddress(location.address);
        setPartners(partners);
        setExistingPartner(location.partner);
        setLoading(false);
        setDeniedAds(location.deniedAds);
      })
      .catch(console.error);
  }, [locationId]);

  function onSubmit(data) {
    const reqBody = {
      name: data.name,
      activity: data.activity,
      address: address,
      partner: existingPartner?._id,
      coordinates: [longitude, latitude],
      deniedAds: deniedAds,
    };

    console.log({ reqBody });

    if (reqBody.coordinates[0] && reqBody.name && reqBody.activity) {
      LocationService.update(locationId, reqBody)
        .then((_) => {
          dispatch(
            loadAlert({
              type: 'info',
              message: 'Location was successfully updated.',
            }),
          );
          history.push(`/locations`);
        })
        .catch(console.error);
    }
  }

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      if (!autocomplete.getPlace().geometry) {
        setIsAddressInvalid(true);
      } else {
        const place = autocomplete.getPlace();
        setLongitude(place.geometry.location.lng());
        setLatitude(place.geometry.location.lat());
        setAddress(getAddress(place));
        setIsAddressInvalid(false);
      }
    } else {
      console.warn('Autocomplete is not loaded yet!');
    }
  };

  const resetFields = () => {
    setLatitude(``);
    setLongitude(``);
    setAddress(``);
    setIsAddressInvalid(false);
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <>
      {!location && <p>Loading...</p>}
      {location && (
        <>
          <DashboardCard
            title={t('components.updateLocation.title', { locationName })}
          >
            <form
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => checkKeyDown(e)}
            >
              <InputField
                label={t(`${cUlIf}.name.label`)}
                defaultValue={location.name}
                name="name"
                placeholder={t(`${cUlIf}.name.placeholder`)}
                inputRef={register({
                  required: t(`${cUlIf}.required`),
                })}
                error={Boolean(errors.name)}
                helperText={errors.name && errors.name.message}
              />
              <InputField
                label={t(`${cUlIf}.activity.label`)}
                defaultValue={location.activity}
                name="activity"
                placeholder={t(`${cUlIf}.activity.placeholder`)}
                inputRef={register({ required: t(`${cUlIf}.required`) })}
                error={Boolean(errors.activity)}
                helperText={errors.activity && errors.activity.message}
              />
              {/* Denied Ads */}
              <DeniedAdsInput
                deniedAds={deniedAds}
                setDeniedAds={setDeniedAds}
              />
              {loading ? (
                <Skeleton height={90} />
              ) : (
                <MuiAutocomplete
                  id="combo-box"
                  value={existingPartner?._id && existingPartner}
                  onChange={(_, newValue) => setExistingPartner(newValue)}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  autoComplete
                  autoHighlight
                  disableClearable
                  getOptionLabel={(option) => option.name || ``}
                  options={partners}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(`${cUlIf}.selectPartner.label`)}
                      placeholder={t(`${cUlIf}.selectPartner.placeholder`)}
                      variant="outlined"
                    />
                  )}
                  style={{ margin: '16px 0 16px 0' }}
                />
              )}
              <LoadScript
                googleMapsApiKey={process.env.GOOGLE_API_KEY}
                libraries={libraries}
              >
                <>
                  {longitude && latitude && (
                    <GoogleMap
                      mapContainerStyle={{
                        height: '280px',
                        width: '100%',
                        borderRadius: 4,
                        border: '1px double lightgrey',
                        marginTop: 24,
                      }}
                      zoom={18}
                      center={{
                        lat: latitude,
                        lng: longitude,
                      }}
                    >
                      <Marker
                        position={{
                          lat: latitude,
                          lng: longitude,
                        }}
                      />
                    </GoogleMap>
                  )}
                  <p>{address}</p>
                  <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={onPlaceChanged}
                    fields={fields}
                    restrictions={componentRestrictions}
                  >
                    <InputField
                      label={t(`${cUlIf}.newAddress.label`)}
                      placeholder={t(`${cUlIf}.newAddress.placeholder`)}
                      onChange={resetFields}
                      error={isAddressInvalid || Boolean(errors.map)}
                      helperText={
                        (isAddressInvalid &&
                          t(`${cUlIf}.newAddress.helperTextError`)) ||
                        (errors.map && errors.map.message)
                      }
                      name="map"
                      inputRef={register()}
                    />
                  </Autocomplete>
                </>
              </LoadScript>
              {/* {isAddressInvalid && (
                <p style={{ color: 'red' }}>Invalid Address!</p>
              )} */}
              <div style={{ marginTop: '2rem' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                  style={{ marginRight: '1rem' }}
                >
                  {t('components.updateLocation.buttons.update')}
                </Button>
                <Button
                  type="button"
                  component={Link}
                  to={`/locations`}
                  variant="contained"
                  disableElevation
                >
                  {t('components.updateLocation.buttons.cancel')}
                </Button>
              </div>
            </form>
          </DashboardCard>
        </>
      )}
    </>
  );
}
