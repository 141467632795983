import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import detector from 'i18next-browser-languagedetector';
import config from './config';

i18n
  .use(detector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    load: 'languageOnly',
    debug: true,

    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${
        window.appConfig?.apps?.adflyer || ''
      }/locales/{{lng}}/{{ns}}.json`,
    },
  });

export default i18n;
