import axios from './axiosConfig';

class HttpError {
  constructor(status, data) {
    this.status = status;
    this.data = data;
  }
}

export default class HttpClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  async get(url) {
    try {
      const response = await axios(`${this.baseUrl}${url}`);
      return await response.data;
    } catch (error) {
      await HttpClient.handleError(error);
    }
  }

  async post(url, body) {
    return this.request(url, body, 'POST');
  }

  async put(url, body) {
    return this.request(url, body, 'PUT');
  }

  async delete(url) {
    try {
      const response = await axios({
        url: `${this.baseUrl}${url}`,
        method: 'DELETE',
      });
      return response;
    } catch (error) {
      await HttpClient.handleError(error);
    }
  }

  async request(url, body, method) {
    try {
      const response = await axios(`${this.baseUrl}${url}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(body),
      });

      return await response.data;
    } catch (error) {
      await HttpClient.handleError(error);
    }
  }

  async fileRequest(url, formData, sendToProgressbar) {
    let percentage = 0;
    try {
      const response = await axios(`${this.baseUrl}${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
        onUploadProgress: (progressEvent) => {
          percentage = parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total),
          );
          sendToProgressbar(percentage);
        },
      });
      sendToProgressbar(0);

      return response;
    } catch (error) {
      await HttpClient.handleError(error);
    }
  }

  static async handleError(error) {
    if (error.response) {
      console.log('error response obj', error.response.data);
      console.log('error response data error', error.response.data.error);
      throw new Error(error.response.data.error);
    } else if (error.request) {
      console.log(error.request);
      throw new HttpError(null, 'connection refused by a server');
    } else {
      console.log('Error', error.message);
    }
    console.log('full-error: ' + error);
  }

  // static async handleError(error) {
  //   if (error.response) {
  //     console.log(error.response.status);
  //     console.log(error.response.data);
  //     console.log(error.response.headers);
  //     throw error.response.data.error;
  //   } else if (error.request) {
  //     console.log(error.request);
  //   } else {
  //     console.log('Error', error.message);
  //   }
  //   console.log('full-error: ' + error);
  // }
}
