import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tableLink: {
    color: theme.palette.primary.light,
    textDecoration: 'none',
    fontWeight: 600,
    fontSize: '120%',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  editIconButton: {
    marginRight: 8,
    position: 'absolute',
    right: 16,
    top: 16,
  },
}));

export default useStyles;
