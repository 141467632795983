import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Draggable } from 'react-smooth-dnd';
import arrayMove from 'array-move';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import VideoService from '../../services/VideoService';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDndItems,
  selectDndButton,
  setDndButton,
} from '../VideoTable/videoSlice';
import CardTitle from '../CardTitle';

export const createUniqueVideoOject = (video) => {
  const random = (Math.random() * 10000).toFixed(0);
  return {
    uniqueId: `${video._id}-${random}`,
    _id: video._id,
    nickname: video.nickname,
    description: video.description,
  };
};

export default function DragNDrop({ playlistMaxSize, receivedItems }) {
  const dispatch = useDispatch();
  const [availableVideos, setAvailableVideos] = useState([]);
  const [items, setItems] = useState(receivedItems || []);
  const button = useSelector(selectDndButton);
  const { t } = useTranslation();

  // update received videos when they are available
  useEffect(() => {
    if (receivedItems) {
      setItems(receivedItems);
    }
  }, [receivedItems]);

  // 1. Download the available videos
  useEffect(() => {
    VideoService.get()
      .then((videos) => {
        setAvailableVideos(videos);
      })

      .catch(console.error);
  }, []);

  // 2. Add a unique ID to enable have duplicates in DND
  const addVideos = (video) => {
    if (!button.disabled) {
      const random = (Math.random() * 10000).toFixed(0);
      if (items?.length < playlistMaxSize || 12) {
        setItems([...items, createUniqueVideoOject(video)]);
        dispatch(setDndItems([...items, createUniqueVideoOject(video)]));
      }
    }
  };

  const removeVideo = (index) => {
    const updatedVideoList = [...items];
    updatedVideoList.splice(index, 1);
    setItems(updatedVideoList);
    dispatch(setDndItems(updatedVideoList));
  };

  const onDrop = ({ removedIndex, addedIndex }) => {
    setItems((items) => arrayMove(items, removedIndex, addedIndex));
  };

  return (
    <>
      <CardTitle customMargin="20px 0">
        {/* TODO: fordítást helyére tenni */}
        {t('pages.createPlaylist.videosInPlaylist')}
      </CardTitle>
      <Autocomplete
        id="combo-box"
        autoComplete
        autoHighlight
        disableClearable
        disabled={button.disabled || items?.length >= (playlistMaxSize || 12)}
        getOptionLabel={(option) => option.nickname || ``}
        options={availableVideos}
        onChange={(e, newValue) => addVideos(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('components.dragNDrop.selectVideos')}
            placeholder={t('components.dragNDrop.chooseOne')}
            variant="outlined"
          />
        )}
        style={{ margin: '16px 0 16px 0' }}
        value={items}
      />
      <Typography>
        {t('components.dragNDrop.dndToReorder')}:{' '}
        <strong>
          {items?.length} / {(playlistMaxSize && playlistMaxSize) || 12}
        </strong>
      </Typography>
      <List
        style={{
          border: '1px solid lightgrey',
          borderRadius: 4,
          margin: '8px 0',
          padding: 8,
          background: '#F0F2F5',
          minHeight: 350,
        }}
      >
        <Container
          dragHandleSelector=".drag-handle"
          lockAxis="y"
          onDrop={onDrop}
        >
          {Array.isArray(items) &&
            items?.map(({ uniqueId, nickname, description }, index) => (
              <Draggable key={uniqueId}>
                <ListItem
                  style={{
                    border: '1px solid lightgrey',
                    marginBottom: 8,
                    background: '#fff',
                    borderRadius: 4,
                  }}
                >
                  <ListItemText primary={nickname} />
                  <ListItemSecondaryAction>
                    <IconButton
                      disabled={button.disabled}
                      onClick={() => removeVideo(index)}
                    >
                      <CancelIcon />
                    </IconButton>
                    <IconButton
                      disableTouchRipple
                      disableRipple
                      disableFocusRipple
                      className="drag-handle"
                      disabled={button.disabled}
                    >
                      <DragIndicatorIcon style={{ cursor: 'grabbing' }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Draggable>
            ))}
        </Container>
      </List>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 8 }}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: 8 }}
          disableElevation
          disabled={button.disabled}
          onClick={() => {
            if (items?.length) {
              dispatch(setDndItems(items));
              dispatch(
                setDndButton({
                  text: `${t('components.dragNDrop.buttons.set')}`,
                  disabled: true,
                }),
              );
            }
          }}
        >
          {button.text}
        </Button>
        <Button
          variant="contained"
          style={{ marginBottom: 8 }}
          disableElevation
          onClick={() => {
            dispatch(
              setDndButton({
                text: `${t('components.dragNDrop.buttons.done')}`,
                disabled: false,
              }),
            );
          }}
        >
          {t('components.dragNDrop.buttons.cancel')}
        </Button>
      </div>
    </>
  );
}

DragNDrop.propTypes = {
  playlistMaxSize: PropTypes.number,
};
