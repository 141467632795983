import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  --font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  margin: 0.5rem 0 1rem 0;
  padding: 15px 10px;
  font-size: var(--font-size);
  position: relative;
`;

const Label = styled.p`
  margin: 0;
  position: absolute;
  top: -8px;
  font-size: 12px;
  background-color: white;
  padding: 0 4px;
  color: rgba(0, 0, 0, 0.57);
`;

const TagList = styled.ul`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
`;

const Tag = styled.li`
  align-items: center;
  background: #85a3bf;
  border-radius: 4px;
  color: white;
  display: flex;
  font-weight: 300;
  list-style: none;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px 10px;
`;

const KeyWordInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: var(--font-size);
`;

const DeleteButton = styled.button`
  align-items: center;
  appearance: none;
  background: #333333;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  height: 15px;
  justify-content: center;
  line-height: 0;
  margin-left: 8px;
  padding: 0;
  transform: rotate(45deg);
  width: 15px;
`;

export default function DeniedAdsInput({ deniedAds, setDeniedAds }) {
  const { t } = useTranslation();
  const tagInput = useRef();

  function removeTag(i) {
    const newTags = [...deniedAds];
    newTags.splice(i, 1);
    setDeniedAds(newTags);
  }

  function inputKeyDown(e) {
    const val = e.target.value;
    if (e.key === 'Enter' && val) {
      e.preventDefault();
      if (deniedAds.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      const updatedKeyWords = [...deniedAds, val];
      setDeniedAds(updatedKeyWords);
      tagInput.current.value = '';
    } else if (e.key === 'Backspace' && !val) {
      removeTag(deniedAds.length - 1);
    }
  }

  return (
    <>
      <Wrapper>
        <Label>{t('components.deniedAds.title')}</Label>
        {deniedAds.length > 0 && (
          <TagList>
            {deniedAds.map((keyword, i) => (
              <Tag key={i}>
                {keyword}
                <DeleteButton
                  type="button"
                  onClick={() => {
                    removeTag(i);
                  }}
                >
                  +
                </DeleteButton>
              </Tag>
            ))}
          </TagList>
        )}
        <KeyWordInput
          autoFocus
          onKeyDown={inputKeyDown}
          ref={(c) => {
            tagInput.current = c;
          }}
        />
      </Wrapper>
    </>
  );
}

DeniedAdsInput.propTypes = {
  deniedAds: PropTypes.array,
  setDeniedAds: PropTypes.func,
};
