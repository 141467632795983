import React, { useState } from 'react';
import useStyles from './styles';
import clsx from 'clsx';
import userAvatar from '../../assets/images/avatar.svg';

import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import AppSelector from '../AppSelector';

import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { openMenu, selectIsSideMenuOpen } from '../SideMenu/sideMenuSlice';
import { selectLoggedInUser } from '../../userSlice';
import { selectContext } from '../../contextSlice';
import { useTranslation } from 'react-i18next';
import useAuth from '../../utils/useAuth';

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSideMenuOpen = useSelector(selectIsSideMenuOpen);
  const [anchorEl, setAnchorEl] = useState(null);
  const isProfileMenuOpen = Boolean(anchorEl);
  const loggedInUser = useSelector(selectLoggedInUser);
  const { t } = useTranslation();
  const { logMeOut } = useAuth();
  const { apps, currentApp } = useSelector(selectContext);

  const { username, fullName, company } = loggedInUser;

  const handleProfileMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      keepMounted
      disableAutoFocusItem
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isProfileMenuOpen}
      onClose={handleMenuClose}
      className={classes.menuContainer}
    >
      <MenuItem
        dense
        className={classes.firstListItem}
        component={Link}
        to={`/settings`}
        onClick={handleMenuClose}
      >
        <Avatar className={classes.avatarLarge} src={userAvatar}>
          A
        </Avatar>
        <Typography style={{ fontWeight: 700 }}>{fullName}</Typography>
        <Typography variant="caption" color="textSecondary" gutterBottom>
          {username}
        </Typography>
        <Typography gutterBottom>{company}</Typography>
      </MenuItem>
      <Divider />
      <ListItem
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={logMeOut}
          className={classes.logoutButton}
        >
          {t('components.header.signOut')}
        </Button>
      </ListItem>
    </Menu>
  );

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, isSideMenuOpen && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          edge="start"
          aria-label="open drawer"
          onClick={() => dispatch(openMenu())}
          className={clsx(
            classes.menuButton,
            isSideMenuOpen && classes.menuButtonHidden,
          )}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          className={classes.title}
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
        >
          {t('components.header.welcome', { fullName })}
        </Typography>
        {apps && <AppSelector apps={apps} currentApp={currentApp} />}
        <Tooltip title={fullName}>
          <IconButton
            disableRipple
            color="inherit"
            onClick={handleProfileMenuOpen}
          >
            <Box border={2} borderRadius="50%" borderColor="grey.400">
              <Avatar src={userAvatar}>{fullName?.charAt(0)}</Avatar>
            </Box>
          </IconButton>
        </Tooltip>
      </Toolbar>
      {renderMenu}
    </AppBar>
  );
};

export default Header;
