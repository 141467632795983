import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import MuiDataTable from '../MuiDataTable';
import DeviceService from '../../services/DeviceService';
import { toFormattedDate } from '../../utils/toFormattedDate';

export default function EventLogTable({ deviceId }) {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const cElTc = 'components.eventLogTable.columns';

  const columns = [
    `${t(`${cElTc}.time`)}`,
    `${t(`${cElTc}.code`)}`,
    `${t(`${cElTc}.message`)}`,
  ];
  const rows = (loading ? Array.from(new Array(3)) : logs).map((log) =>
    log
      ? [toFormattedDate(log.time), log?.code, log?.messageData?.message]
      : Array.from({ length: columns.length }, () => <Skeleton />),
  );

  useEffect(() => {
    DeviceService.getEventLog(deviceId)
      .then((log) => {
        setLogs(log);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [deviceId]);

  return (
    <>
      <MuiDataTable
        title={t('components.eventLogTable.title')}
        columns={columns}
        rows={rows}
      />
    </>
  );
}

EventLogTable.propTypes = {
  deviceId: PropTypes.string,
};
