export default [
  {
    name: 'name',
    required: true,
  },
  {
    name: 'companyName',
    required: true,
  },
  {
    name: 'companyRegNo',
    pattern: {
      value: /^\d{2}-\d{2}-\d{6}$/i,
      message: 'Format: 01-09-123456',
    },
    required: false,
  },
  {
    name: 'billingAddress',
  },
  {
    name: 'vatRegNo',
    pattern: {
      value: /^\d{8}-\d{1}-\d{2}$/i,
      message: 'Format: 12345678-2-41',
    },
    required: true,
  },
  {
    name: 'representative',
    required: true,
  },
  {
    name: 'contactPerson',
    required: true,
  },
  {
    name: 'phone',
    pattern: {
      value: /^\+36(20|30|70|31)\d{7}$/i,
      message: 'Format: +36201234567',
    },
    required: true,
  },
  {
    name: 'email',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Please enter a valid email address.',
    },
    required: true,
  },
  {
    name: 'shippingAddress',
    required: true,
  },
];
