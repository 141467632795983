import React from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

const VideoPlayer = ({ url, playing = true }) => {
  return (
    <ReactPlayer
      url={url}
      width="100%"
      height="95%"
      controls={true}
      playing={playing}
      muted={true}
    />
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default VideoPlayer;
