import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import DashboardCard from '../DashboardCard';
import { FaBan } from 'react-icons/fa';
import { useTheme } from '@material-ui/styles';

const TagList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 1rem;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
  svg {
    fill: ${(p) => p.iconColor};
  }
`;

export default function DeniedAds({ location }) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <DashboardCard title={t('components.deniedAds.title')}>
        {!location && <p>Loading...</p>}
        {location && !location?.deniedAds.length && (
          <Alert severity="info">No denied ads</Alert>
        )}
        {location?.deniedAds && (
          <TagList>
            {location.deniedAds.map((deniedAd, i) => (
              <ListItem key={i} iconColor={theme.palette.error.main}>
                <FaBan />
                {deniedAd}
              </ListItem>
            ))}
          </TagList>
        )}
      </DashboardCard>
    </>
  );
}

DeniedAds.propTypes = {
  location: PropTypes.object,
};
