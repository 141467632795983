import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PlaylistService from '../../services/PlaylistService';

import DashboardCard from '../../components/DashboardCard';
import PlaylistForm from '../../components/PlaylistForm';
import DragNDrop from '../../components/DragNDrop';
import {
  selectDndItems,
  setDndItems,
  selectDndButton,
  setDndButton,
} from '../../components/VideoTable/videoSlice';
// import { createUniqueVideoOject } from '../../components/DragNDrop';
import anyValueIsEmpty from '../../utils/anyValueIsEmpty';

export const createUniqueVideoOject = (video) => {
  const random = (Math.random() * 10000).toFixed(0);
  return {
    uniqueId: `${video._id}-${random}`,
    _id: video._id,
    nickname: video.nickname,
    description: video.description,
  };
};

function getUniqueVideoList(videos) {
  return videos.map((video) => createUniqueVideoOject(video));
}

export default function UpdatePlaylistPage() {
  const history = useHistory();
  const { playlistId } = useParams();
  const [playlist, setPlaylist] = useState(null);
  const [nickname, setNickname] = useState('');
  const [loading, setLoading] = useState(true);
  const [muiDate, setMuiDate] = useState(DateTime.local().toISO());
  const [startImmediately, setStartImmediately] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [uniqueVideoList, setUniqueVideoList] = useState(null);
  const dndItems = useSelector(selectDndItems);
  const dndButton = useSelector(selectDndButton);
  const { t } = useTranslation();
  const playlistNickname = playlist?.nickname;

  const isSubmitDisabled = anyValueIsEmpty(
    nickname,
    startTime || startImmediately || muiDate,
    dndItems.length,
    dndButton.disabled,
  );

  useEffect(() => {
    PlaylistService.get(playlistId)
      .then((data) => {
        setPlaylist(data);
        // TODO: ?
        setStartTime(data.startTime);
        setMuiDate(data.startTime);
        setNickname(data.nickname);
        setUniqueVideoList(getUniqueVideoList(data.videos));
      })
      .catch(console.error);
  }, [playlistId]);

  const handleMuiDate = (dt) => {
    setMuiDate(dt?.toISO());
  };

  const handleSwitch = (e) => {
    // TODO: simplify this
    setStartImmediately(e.target.checked);
    setStartTime(e.target.checked ? DateTime.local()?.toISO() : selectedDate);
  };

  function handleUpdate(e) {
    e.preventDefault();
    const reqBody = {
      nickname,
      startTime: startImmediately ? startTime : muiDate,
      videos: dndItems?.map((video) => video._id),
      devices: [playlist?.devices[0]?._id],
    };
    console.log(reqBody);
    PlaylistService.update(playlist._id, reqBody)
      .then((res) => {
        console.log;
        history.push(`/devices/playlists/${playlist?.devices[0]?._id}`);
      })
      .catch(console.error);
  }

  return (
    <>
      <Helmet>
        <title>
          {t('pages.updatePlaylistPage.title', { playlistNickname })}
        </title>
      </Helmet>
      <Grid container>
        <Grid item xs={12}>
          <DashboardCard
            title={t('pages.updatePlaylistPage.title', { playlistNickname })}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <PlaylistForm
                  nickname={nickname}
                  setNickname={setNickname}
                  loading={loading}
                  selectedDevice={playlist?.devices[0]}
                  muiDate={muiDate}
                  handleMuiDate={handleMuiDate}
                  startImmediately={startImmediately}
                  handleSwitch={handleSwitch}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DragNDrop
                  playlistMaxSize={playlist?.devices[0]?.playlistMaxSize}
                  receivedItems={uniqueVideoList}
                />
              </Grid>
            </Grid>
          </DashboardCard>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            style={{ marginTop: 16 }}
            disabled={isSubmitDisabled}
            onClick={handleUpdate}
          >
            {t('pages.updatePlaylistPage.buttons.save')}
          </Button>
          <Button
            disableElevation
            style={{ margin: '16px 0 0 8px' }}
            component={Link}
            to={`/devices/playlists/${playlist?.devices[0]?._id}`}
          >
            {t('pages.updatePlaylistPage.buttons.cancel')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
