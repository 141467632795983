import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import capitalize from 'capitalize';
import VideoService from '../../services/VideoService';
import VideoPlayer from '../../components/VideoPlayer';

const VideoDetails = () => {
  const { videoId } = useParams();
  const [video, setVideo] = useState(null);
  const { t } = useTranslation();
  const videoName = video?.nickname || t('general.loading');
  const pageName = capitalize(t('components.menuItems.videos'));

  useEffect(() => {
    VideoService.get(videoId)
      .then((video) => {
        setVideo(video);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [videoId]);

  return (
    <>
      <Helmet>
        <title>{`${videoName} | ${pageName}`}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          {video && <VideoPlayer url={video?.s3Link} playing={false} />}
        </Grid>
        <Grid item xs={12} sm={9} md={6}>
          {video ? (
            <>
              <Typography variant={`h5`}>{video.nickname}</Typography>
            </>
          ) : (
            `loading video details...`
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default VideoDetails;
