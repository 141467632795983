import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from '../../userSlice';
import { selectContext } from '../../contextSlice';
import jwtDecode from 'jwt-decode';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Copyright from '../../components/Copyright';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import ProgressBackdrop from '../../components/ProgressBackdrop';
import AlertBar from '../../components/AlertBar';

import UserService from '../../services/UserService';
import { siteMetadata } from '../../constants';

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleSubmit, register, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const { logo } = useSelector(selectContext);
  const pageName = t('pages.login.title');

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const onSubmit = (data) => {
    setError(null);
    setLoading(true);
    const credentials = {
      username: data.email.toLowerCase(),
      password: data.password,
    };

    UserService.login(credentials)
      .then((res) => {
        if (res.success) {
          setLoading(false);
          localStorage.setItem('access-token', res.token);
          return jwtDecode(res.token);
        }
      })
      .then((user) => dispatch(loadUser(user)))
      .catch((error) => {
        setLoading(false);
        setError(t('pages.login.unauthorized'));
      });
  };

  return (
    <>
      <Helmet>
        <title>{`${pageName} | ${siteMetadata.TITLE}`}</title>
      </Helmet>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper className={classes.paper} elevation={8}>
          <img src={logo} className={classes.logo} alt="company-logo" />
          <Typography component="h1" variant="h5">
            {t('pages.login.title')}
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('pages.login.inputFields.email')}
              name="email"
              autoComplete="email"
              autoFocus
              inputRef={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: `${t('pages.login.validation.invalidEmail')}`,
                },
              })}
              error={Boolean(errors.email)}
              helperText={
                (errors.email && errors.email.message) ||
                `${t('pages.login.validation.required')}`
              }
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('pages.login.inputFields.password')}
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              inputRef={register({ required: true })}
              error={Boolean(errors.password)}
              helperText={`${t('pages.login.validation.required')}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disableElevation
              disabled={loading}
            >
              {loading
                ? `${t('pages.login.buttonText.loading')}`
                : `${t('pages.login.buttonText.login')}`}
            </Button>
          </form>
          {/* <Box mt={8}>
          <Copyright />
        </Box> */}
        </Paper>
        <ProgressBackdrop open={loading} />
        {error ? <AlertBar type="error" message={error} /> : null}
      </Container>
    </>
  );
};

export default Login;
