import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import UpdatePartner from '../../components/UpdatePartner';
import { siteMetadata } from '../../constants';

export default function UpdatePartnerPage() {
  const { partnerId } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${t('general.updatePartner')} | ${siteMetadata.TITLE}`}</title>
      </Helmet>
      <UpdatePartner partnerId={partnerId} />
    </>
  );
}
