import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 600,
  },
  tableContainer: {
    padding: theme.spacing(3),
  },
  editButton: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  archiveButton: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  automata: {
    color: theme.palette.primary.light,
    textDecoration: 'none',
    fontWeight: 600,
    fontSize: '120%',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
