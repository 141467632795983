import React from 'react';
import useStyles from './styles';
import PropTypes from 'prop-types';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const ProgressBackdrop = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" thickness={8} />
    </Backdrop>
  );
};

ProgressBackdrop.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default ProgressBackdrop;
