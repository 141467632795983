import React from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const MuiDataTable = ({ rows, columns, title }) => {
  const { t } = useTranslation();
  const cmt = 'components.muiDataTable.textLabels';

  const getMuiTheme = (theme) =>
    createMuiTheme({
      overrides: {
        MUIDataTableToolbar: {
          titleText: {
            color: 'white',
            background: theme.palette.primary.light,
            padding: '0 8px',
            borderRadius: 3,
            display: 'inline',
          },
        },
        MUIDataTableBodyRow: {
          root: {
            '&:nth-of-type(odd)': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        },
      },
    });

  const options = {
    filterType: 'multiselect',
    elevation: 1,
    download: false,
    print: false,
    searchPlaceholder: `${t('components.muiDataTable.searchPlaceholder')}`,
    selectableRows: 'none',
    rowHover: false,
    rowsPerPage: 25,

    rowsPerPageOptions: [25, 50, 100],
    textLabels: {
      body: {
        noMatch: `${t(`${cmt}.body.noMatch`)}`,
        toolTip: `${t(`${cmt}.body.toolTip`)}`,
        columnHeaderTooltip: ({ label }) =>
          `${t(`${cmt}.body.columnHeaderTooltip`, { label })}`,
      },
      pagination: {
        next: `${t(`${cmt}.pagination.next`)}`,
        previous: `${t(`${cmt}.pagination.previous`)}`,
        rowsPerPage: `${t(`${cmt}.pagination.rowsPerPage`)}`,
        displayRows: `${t(`${cmt}.pagination.displayRows`)}`,
      },
      toolbar: {
        search: `${t(`${cmt}.toolbar.search`)}`,
        downloadCsv: `${t(`${cmt}.toolbar.downloadCsv`)}`,
        print: `${t(`${cmt}.toolbar.print`)}`,
        viewColumns: `${t(`${cmt}.toolbar.viewColumns`)}`,
        filterTable: `${t(`${cmt}.toolbar.filterTable`)}`,
      },
      filter: {
        all: `${t(`${cmt}.filter.all`)}`,
        title: `${t(`${cmt}.filter.title`)}`,
        reset: `${t(`${cmt}.filter.reset`)}`,
      },
      viewColumns: {
        title: `${t(`${cmt}.viewColumns.title`)}`,
        titleAria: `${t(`${cmt}.viewColumns.titleAria`)}`,
      },
      selectedRows: {
        text: `${t(`${cmt}.selectedRows.text`)}`,
        delete: `${t(`${cmt}.selectedRows.delete`)}`,
        deleteAria: `${t(`${cmt}.selectedRows.deleteAria`)}`,
      },
    },
  };

  return (
    <MuiThemeProvider theme={getMuiTheme}>
      <MUIDataTable
        title={title}
        data={rows}
        columns={columns}
        options={options}
      />
    </MuiThemeProvider>
  );
};

export default MuiDataTable;
