import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import capitalize from 'capitalize';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link, useRouteMatch } from 'react-router-dom';
import PartnerTable from '../../components/PartnerTable';
import { siteMetadata } from '../../constants';

export default function PartnersPage() {
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const pageName = capitalize(t('components.menuItems.partners'));

  return (
    <>
      <Helmet>
        <title>{`${pageName} | ${siteMetadata.TITLE}`}</title>
      </Helmet>
      <Button
        disableElevation
        variant="contained"
        color="secondary"
        style={{ marginBottom: 16, marginRight: 16 }}
        component={Link}
        to={`${url}/new`}
      >
        {t('pages.partnersPage.addNew')}
      </Button>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PartnerTable />
        </Grid>
      </Grid>
    </>
  );
}
