import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { Marker, MarkerClusterer, InfoWindow } from '@react-google-maps/api';
import GoogleMap from '../GoogleMap';
import DashboardCard from '../DashboardCard';
import useStyles from '../../styles';

// MarkerClusterer docs:
// https://react-google-maps-api-docs.netlify.app/#markerclusterer

function getLocationsFromDevices(devices) {
  const locations = devices
    .filter((device) => device?.location !== null)
    .map((device) => {
      return {
        lat: device?.location?.coordinates[1],
        lng: device?.location?.coordinates[0],
        name: device?.name,
        deviceId: device?._id,
        location: device?.location?.name,
        address: device?.location?.address,
      };
    });
  return locations;
}

export default function GoogleMapDevices({
  title,
  zoom,
  center,
  devices,
  loading,
  deviceLocation = null,
}) {
  const classes = useStyles();
  function createKey(location) {
    return location.lat + location.lng;
  }

  return (
    <>
      <DashboardCard title={title}>
        {deviceLocation && (
          <>
            <Link
              to={`/locations/details/${deviceLocation._id}`}
              className={classes.tableLink}
            >
              {deviceLocation.name}
            </Link>
            <Typography>{deviceLocation.address}</Typography>
          </>
        )}
        {loading ? (
          <Skeleton variant="rect" height={400} />
        ) : (
          <GoogleMap
            mapContainerStyle={{
              height: 400,
              width: '100%',
              margin: '1rem 0',
            }}
            zoom={zoom}
            center={center}
          >
            <MarkerClusterer>
              {(clusterer) =>
                getLocationsFromDevices(devices).map((location, i) => (
                  <MarkerWithInfoWindow
                    key={`${createKey(location)}${i}`}
                    location={location}
                    clusterer={clusterer}
                  />
                ))
              }
            </MarkerClusterer>
          </GoogleMap>
        )}
      </DashboardCard>
    </>
  );
}

GoogleMapDevices.propTypes = {
  title: PropTypes.string,
  zoom: PropTypes.number,
  center: PropTypes.object,
  devices: PropTypes.array,
  loading: PropTypes.bool,
  deviceLocation: PropTypes.object,
};

function MarkerWithInfoWindow({ location, clusterer }) {
  const [show, setShow] = useState(false);
  const classes = useStyles();

  return (
    <Marker
      position={location}
      clusterer={clusterer}
      onClick={() => setShow(true)}
    >
      {show && (
        <InfoWindow position={location} onCloseClick={() => setShow(false)}>
          <div
            style={{
              background: `white`,
              padding: 5,
            }}
          >
            <Link
              to={`/devices/details/${location.deviceId}`}
              className={classes.tableLink}
            >
              <h3>{location?.name}</h3>
            </Link>
            <p>Helyszín: {location?.location}</p>
            <p>{location?.address}</p>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
}

MarkerWithInfoWindow.propTypes = {
  location: PropTypes.object,
  clusterer: PropTypes.object,
};
