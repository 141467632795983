import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { useDevice } from '../../utils/useDevice';
import DeviceDetails from '../../components/DeviceDetails';
import DeviceLocation from '../../components/DeviceLocation';
import UpdateDevice from '../../components/UpdateDevice';
import DevicePageNav from '../../components/DevicePageNav';
import DeviceStatus from '../../components/DeviceStatus';

export default function DeviceDetailsPage() {
  const { deviceId } = useParams();
  const { t } = useTranslation();
  // TODO: update translations
  const pDdT = 'pages.deviceDetails.tabs';
  const [editState, setEditState] = useState(false);
  const device = useDevice(deviceId);
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>{`${device?.name || t('general.loading')} | Details`}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DevicePageNav deviceId={deviceId} />
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(3),
              }}
            >
              {!device && (
                <Skeleton variant="rect" height={400} animation="wave" />
              )}
              {device && (
                <>
                  {!editState ? (
                    <DeviceDetails
                      remoteDevice={device}
                      deviceId={deviceId}
                      setEditState={setEditState}
                      editState={editState}
                    />
                  ) : (
                    <UpdateDevice
                      remoteDevice={device}
                      setEditState={setEditState}
                      editState={editState}
                    />
                  )}
                </>
              )}
              <DeviceStatus deviceId={deviceId} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DeviceLocation deviceId={deviceId} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
