import React from 'react';
import { Helmet } from 'react-helmet';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DashboardCard from '../../components/DashboardCard';
import InputField from '../../components/InputField';
import useFormInput from '../../utils/useFormInput';
import DeviceService from '../../services/DeviceService';
import { siteMetadata } from '../../constants';

const CreateDevice = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const name = useFormInput('');
  const type = useFormInput('Media Device');
  const playlistMaxSize = useFormInput(12);
  const displaySize = useFormInput(0);
  const displayType = useFormInput('');
  const mobileProvider = useFormInput('');

  const handleCreate = () => {
    const newDevice = {
      name: name.value,
      // TODO: Location Dropdown
      address: 'Here should be a Location',
      type: type.value,
      playlistMaxSize: parseInt(playlistMaxSize.value),
      displaySize: parseInt(displaySize.value),
      displayType: displayType.value,
      mobileProvider: mobileProvider.value,
    };

    DeviceService.create(newDevice)
      .then((_) => {
        // TODO: Toast?
        history.push('/devices');
      })
      .catch(console.error);
  };

  return (
    <>
      <Helmet>
        <title>{`${t('pages.createDevice.title')} | ${
          siteMetadata.TITLE
        }`}</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <DashboardCard title={t('pages.createDevice.title')}>
            <InputField
              autoFocus
              label={t('pages.createDevice.inputFields.name')}
              {...name}
            />
            <InputField
              type={`number`}
              label={t('pages.createDevice.inputFields.playlistMaxSize')}
              {...playlistMaxSize}
            />
            <InputField
              type={`number`}
              label={t('pages.createDevice.inputFields.displaySize')}
              {...displaySize}
            />
            <InputField
              label={t('pages.createDevice.inputFields.displayType')}
              {...displayType}
            />
            <InputField
              label={t('pages.createDevice.inputFields.mobileProvider')}
              {...mobileProvider}
            />
            <InputField
              label={t('pages.createDevice.inputFields.type')}
              disabled
              {...type}
            />
            <div style={{ marginTop: 16 }}>
              <Button
                component={Link}
                to="/devices"
                style={{ marginRight: 16 }}
              >
                {t('pages.createDevice.buttons.cancel')}
              </Button>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                onClick={handleCreate}
              >
                {t('pages.createDevice.buttons.create')}
              </Button>
            </div>
          </DashboardCard>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateDevice;
