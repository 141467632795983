import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@material-ui/styles';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const deviceMenuItems = [
  {
    name: 'details',
    path: 'details',
  },
  {
    name: 'playlists',
    path: 'playlists',
  },
  {
    name: 'eventLog',
    path: 'event-log',
  },
];

const StyledListItem = withStyles((theme) => ({
  selected: {
    fontWeight: 500,
    borderBottom: `3px solid ${theme.palette.secondary.light}`,
  },
}))(ListItem);

export default function DevicePageNav({ deviceId }) {
  const location = useLocation();
  const { pathname } = location;
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <List
        style={{
          display: 'flex',
          marginBottom: theme.spacing(3),
          fontSize: 16,
        }}
      >
        {deviceMenuItems.map((menuItem, index) => (
          <StyledListItem
            disableRipple
            key={index}
            button
            selected={pathname.includes(menuItem.path)}
            component={Link}
            to={`/devices/${menuItem.path}/${deviceId}`}
            style={{ textAlign: 'center', display: 'block' }}
          >
            {t(`components.devicePageNav.${menuItem.name}`)}
          </StyledListItem>
        ))}
      </List>
    </>
  );
}

DevicePageNav.propTypes = {
  deviceId: PropTypes.string.isRequired,
};
