import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import DashboardCard from '../DashboardCard';
import OpeningHours from '../OpeningHours';
import LocationService from '../../services/LocationService';
import { createRequestBody } from '../OpeningHours/OpeningHours';
import { selectOpeningHours } from '../LocationTable/locationSlice';
import { loadAlert } from '../../userSlice';

export default function CreateOpeningHours({ location }) {
  const dispatch = useDispatch();
  const data = useSelector(selectOpeningHours);
  const { t } = useTranslation();

  function handleSubmit() {
    const reqBody = {
      openingHours: createRequestBody(data),
    };

    LocationService.updateHours(location?._id, reqBody)
      .then((res) => {
        dispatch(
          loadAlert({
            type: 'success',
            message: `${t('components.updateOpeningHours.successfulUpdate')}`,
          }),
        );
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          loadAlert({
            message:
              err.message ||
              `${t('components.updateOpeningHours.errorMessage')}`,
          }),
        );
      });
  }

  return (
    <>
      {!location ? (
        `Loading...`
      ) : (
        <DashboardCard title={t('components.updateOpeningHours.title')}>
          <OpeningHours location={location} />
          <div>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disableElevation
              color="primary"
              style={{ marginTop: 8 }}
            >
              {t('components.updateOpeningHours.buttons.save')}
            </Button>
          </div>
        </DashboardCard>
      )}
    </>
  );
}

CreateOpeningHours.propTypes = {
  location: PropTypes.object,
};
