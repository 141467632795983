import HttpClient from '../utils/httpClient';
import config from '../config';

let httpClientDSS = new HttpClient(config.baseUrlDss);

export default {
  get,
  fileUpload,
  deleteVideo,
  updateVideo,
  getCountPlay,
  getSumPlay,
  getStats,
};

async function get(videoId = '') {
  return await httpClientDSS.get(`/videos/${videoId}`);
}

async function fileUpload(formData, cb) {
  return await httpClientDSS.fileRequest('/videos/new', formData, cb);
}

async function deleteVideo(videoId) {
  return await httpClientDSS.delete(`/videos/delete/${videoId}`);
}

async function updateVideo(videoId, reqBody) {
  return await httpClientDSS.put(`/videos/update/${videoId}`, reqBody);
}

async function getCountPlay(videoId) {
  return await httpClientDSS.get(`/videos/count-play/${videoId}`);
}

async function getSumPlay(videoId) {
  return await httpClientDSS.get(`/videos/sum-play/${videoId}`);
}

async function getStats(videoId) {
  return await httpClientDSS.get(`/videos/video-stats/${videoId}`);
}
