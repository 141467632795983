import React from 'react';
import List from '@material-ui/core/List';
import MenuItem from './MenuItem';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';

// Icon names from: https://materializecss.com/icons.html
const menuItems = [
  {
    name: 'dashboard',
    icon: 'dashboard',
    active: true,
  },
  {
    name: 'devices',
    icon: 'devices',
    active: true,
  },
  {
    name: 'partners',
    icon: 'business_center',
    active: true,
  },
  {
    name: 'locations',
    icon: 'location_on',
    active: true,
  },
  // {
  //   name: 'playlists',
  //   icon: 'playlist_play',
  //   active: true,
  // },
  {
    name: 'videos',
    icon: 'local_movies',
    active: true,
  },
  {
    name: 'statistics',
    icon: 'insert_chart',
    active: false,
  },
];

const secondaryMenuItems = [
  {
    name: 'settings',
    icon: 'settings',
    active: true,
  },
];

const MenuItems = () => {
  const { t } = useTranslation();

  return (
    <>
      <List>
        {menuItems.map((item, index) => {
          return (
            <MenuItem
              key={`menu-${index}`}
              menuName={t(`components.menuItems.${item.name}`)}
              menuPath={`/${item.name}`}
              menuIcon={item.icon}
              active={item.active}
            />
          );
        })}
      </List>
      <Divider />
      <List>
        {secondaryMenuItems.map((item, index) => {
          return (
            <MenuItem
              key={`menu-${index}`}
              menuName={t(`components.menuItems.${item.name}`)}
              menuPath={`/${item.name}`}
              menuIcon={item.icon}
              active={item.active}
            />
          );
        })}
      </List>
    </>
  );
};

export default MenuItems;
