import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import { AiFillFolderOpen } from 'react-icons/ai';
import Skeleton from '@material-ui/lab/Skeleton';
import { selectVideos, selectIsVideosLoaded, loadVideos } from './videoSlice';
import { loadAlert } from '../../userSlice';
import VideoService from '../../services/VideoService';
import { Link } from 'react-router-dom';
import ConfirmDialog from '../ConfirmDialog';
import ProgressBackdrop from '../ProgressBackdrop';
import MuiDataTable from '../MuiDataTable';
import { useTranslation } from 'react-i18next';

import FullScreenDialog from '../FullScreenDialog';
import VideoPlayer from '../VideoPlayer';

const VideoTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const { logMeOut } = useJwt();
  const { t } = useTranslation();

  const videosList = useSelector(selectVideos);
  const isVideosLoaded = useSelector(selectIsVideosLoaded);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [videoIdToBeDeleted, setVideoIdToBeDeleted] = useState('');
  const [videoNameToBeDeleted, setVideoNameToBeDeleted] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [s3Link, setS3Link] = useState('');

  useEffect(() => {
    console.log('videolista lekérés videoTable-ben');
    VideoService.get()
      .then((videos) => {
        dispatch(loadVideos(videos));
      })
      .catch((err) => {
        console.error(`Error in getting videos: ${err}`);
        dispatch(
          loadAlert({
            type: 'error',
            message: err,
          }),
        );
        if (err === 'Invalid credentials!') {
          // logMeOut();
          console.log('here should be a logout');
        }
      });
  }, [dispatch]);

  const openConfirmDialog = (videoId, videoName) => {
    setIsConfirmDialogOpen(true);
    setVideoIdToBeDeleted(videoId);
    setVideoNameToBeDeleted(videoName);
  };

  const closeCoinfirmDialog = () => {
    setIsConfirmDialogOpen(false);
    setVideoIdToBeDeleted('');
    setVideoNameToBeDeleted('');
  };

  const deleteVideo = () => {
    setLoading(true);
    closeCoinfirmDialog();
    VideoService.deleteVideo(videoIdToBeDeleted)
      .then((res) => {
        if ((res.status = '204')) {
          let updatedVideoList = videosList.filter(
            (video) => video._id !== videoIdToBeDeleted,
          );
          dispatch(loadVideos(updatedVideoList));
          dispatch(
            loadAlert({
              type: 'success',
              message: `Video "${videoNameToBeDeleted}" was successfully archived.`,
            }),
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          loadAlert({
            type: 'error',
            message: err.message,
          }),
        );
      });
  };

  const handleOpenPreview = (title, url) => {
    setOpen(true);
    setTitle(title);
    setS3Link(url);
  };

  const columns = [
    `${t('components.videoTable.columns.name')}`,
    `${t('components.videoTable.columns.details')}`,
    `${t('components.videoTable.columns.description')}`,
    `${t('components.videoTable.columns.format')}`,
    `${t('components.videoTable.columns.duration')}`,
    // `${t('components.videoTable.columns.company')}`,
    `${t('components.videoTable.columns.actions')}`,
  ];

  let rows = (!isVideosLoaded ? Array.from(new Array(3)) : videosList).map(
    (video, i) => [
      video ? video.nickname : <Skeleton />,
      video ? (
        <IconButton
          key={i}
          component={Link}
          to={`/videos/details/${video?._id}`}
        >
          <AiFillFolderOpen
            style={{
              fontSize: 28,
            }}
          />
        </IconButton>
      ) : (
        <Skeleton />
      ),
      video ? video.description : <Skeleton />,
      video ? video.format : <Skeleton />,
      video ? `${video.duration}s` : <Skeleton />,
      // video ? video.company : <Skeleton />,
      video ? (
        <>
          <Tooltip title={t('components.videoTable.tooltips.preview')}>
            <IconButton
              size="small"
              onClick={() => handleOpenPreview(video.nickname, video.s3Link)}
              style={{ marginRight: 8 }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('components.videoTable.tooltips.edit')}>
            <IconButton
              size="small"
              style={{ marginRight: 8 }}
              component={Link}
              to={`/videos/update/${video._id}`}
            >
              <EditIcon className={classes.editButton} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('components.videoTable.tooltips.archive')}>
            <IconButton
              size="small"
              onClick={() => openConfirmDialog(video._id, video.nickname)}
              style={{ marginRight: 8 }}
            >
              <ArchiveIcon className={classes.archiveButton} />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Skeleton variant="circle" width={20} height={20} />
      ),
    ],
  );

  return (
    <>
      <MuiDataTable
        rows={rows}
        columns={columns}
        title={t('components.videoTable.title')}
      />
      <ConfirmDialog
        open={isConfirmDialogOpen}
        cancel={closeCoinfirmDialog}
        confirm={deleteVideo}
        confirmButtonText={`Archive`}
        message={t('components.videoTable.confirmDelete', {
          videoNameToBeDeleted,
        })}
      />
      <ProgressBackdrop open={loading} />
      <FullScreenDialog
        open={open}
        setOpen={setOpen}
        title={title}
        setS3Link={setS3Link}
      >
        <VideoPlayer url={s3Link} />
      </FullScreenDialog>
    </>
  );
};

export default VideoTable;
