import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputField from '../InputField';
import UserService from '../../services/UserService';
import { loadAlert } from '../../userSlice';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function ChangePassword() {
  const dispatch = useDispatch();
  const { errors, register, handleSubmit, watch, reset } = useForm();
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleMouseDownOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const newPassword = useRef({});
  newPassword.current = watch('newPassword', '');

  function onSubmit(data) {
    setSaving(true);
    UserService.changePassword(data)
      .then((res) => {
        setSaving(false);
        reset();
        return res.username;
      })
      .then((username) => {
        dispatch(
          loadAlert({
            type: 'info',
            message: t('components.changePassword.alerts.success', {
              username,
            }),
          }),
        );
      })
      .catch((err) => {
        console.error(err);
        setSaving(false);
        dispatch(
          loadAlert({
            type: 'error',
            message: err.message || t('components.changePassword.alerts.error'),
          }),
        );
      });
  }

  return (
    <>
      <Typography>{t('components.changePassword.title')}</Typography>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <InputField
          label={t('components.changePassword.labels.oldPassword')}
          type={showOldPassword ? 'text' : 'password'}
          name="oldPassword"
          inputRef={register({
            required: t('components.changePassword.required'),
          })}
          error={Boolean(errors.oldPassword)}
          helperText={
            (errors.oldPassword && errors.oldPassword.message) ||
            t('components.changePassword.required')
          }
          disabled={saving}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowOldPassword}
                  onMouseDown={handleMouseDownOldPassword}
                >
                  {showOldPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <InputField
          label={t('components.changePassword.labels.newPassword')}
          type={showNewPassword ? 'text' : 'password'}
          name="newPassword"
          inputRef={register({
            required: t('components.changePassword.required'),
            pattern: {
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
              message: t('components.changePassword.strongPasswordInfo'),
            },
          })}
          error={Boolean(errors.newPassword)}
          helperText={
            (errors.newPassword && errors.newPassword.message) ||
            t('components.changePassword.strongPasswordInfo')
          }
          disabled={saving}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownNewPassword}
                >
                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <InputField
          label={t('components.changePassword.labels.confirmPassword')}
          type={showConfirmPassword ? 'text' : 'password'}
          name="confirmPassword"
          inputRef={register({
            required: t('components.changePassword.required'),
            validate: (value) =>
              value === newPassword.current ||
              `${t('components.changePassword.passwordsDontMatch')}`,
          })}
          error={Boolean(errors.confirmPassword)}
          helperText={
            (errors.confirmPassword && errors.confirmPassword.message) ||
            t('components.changePassword.required')
          }
          disabled={saving}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownConfirmPassword}
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
          style={{ marginTop: 8 }}
          disabled={saving}
        >
          {t('components.changePassword.save')}
        </Button>
      </form>
    </>
  );
}
