import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Skeleton from '@material-ui/lab/Skeleton';
import DashboardCard from '../DashboardCard';
import { deviceStatuses } from '../../constants';
// Not Activated
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
// OK
import CloudDoneRoundedIcon from '@material-ui/icons/CloudDoneRounded';
// Standby
import NightsStayRoundedIcon from '@material-ui/icons/NightsStayRounded';
// Warning
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
// Error
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

export default function DevicesWithProblems({
  activatedDevices,
  devicesWithProblems,
}) {
  const { t } = useTranslation();

  return (
    <>
      <DashboardCard title={t('components.devicesWithProblems.title')}>
        <List component="nav" aria-label="main mailbox folders">
          {(devicesWithProblems
            ? devicesWithProblems
            : Array.from(new Array(2))
          ).map((deviceWithProblem, i) => (
            <ListItem
              button
              key={i}
              component={Link}
              to={`/devices/event-log/${deviceWithProblem?._id}`}
            >
              <ListItemIcon>
                {!deviceWithProblem && (
                  <Skeleton variant="circle" width={40} height={40} />
                )}
                {deviceWithProblem?.status === deviceStatuses.ERROR && (
                  <ErrorIcon />
                )}
                {deviceWithProblem?.status === deviceStatuses.WARNING && (
                  <WarningIcon />
                )}
              </ListItemIcon>
              <ListItemText
                primary={deviceWithProblem?.name || <Skeleton />}
                secondary={
                  deviceWithProblem ? (
                    `${deviceWithProblem?.location?.name || 'No location'} | ${
                      deviceWithProblem?.status
                    }`
                  ) : (
                    <Skeleton />
                  )
                }
              />
            </ListItem>
          ))}

          {/* {(activatedDevices ? activatedDevices : Array.from(new Array(2))).map(
            (activatedDevice, i) => (
              <ListItem
                button
                key={i}
                component={Link}
                to={`/devices/details/${activatedDevice?._id}`}
              >
                <ListItemIcon>
                  {activatedDevice?.status === deviceStatuses.NOT_ACTIVATED && (
                    <NotActivatedIcon />
                  )}
                  {activatedDevice?.status === deviceStatuses.OK && <OkIcon />}
                  {activatedDevice?.status === deviceStatuses.STANDBY && <StandbyIcon />}
                  {activatedDevice?.status === deviceStatuses.WARNING && <WarningIcon />}
                  {activatedDevice?.status === deviceStatuses.ERROR && <ErrorIcon />}
                </ListItemIcon>
                <ListItemText
                  primary={activatedDevice?.name || <Skeleton />}
                  secondary={
                    `${activatedDevice?.location?.name} | Most fut: ${activatedDevice?.actualPlaylist?.nickname || `-`
                    }` || <Skeleton />
                  }
                />
              </ListItem>
            ),
          )} */}
        </List>
      </DashboardCard>
    </>
  );
}

export const NotActivatedIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.grey[500],
  },
}))(BlockRoundedIcon);

export const OkIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.success.main,
  },
}))(CloudDoneRoundedIcon);

export const StandbyIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.info.light,
  },
}))(NightsStayRoundedIcon);

export const WarningIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.warning.main,
  },
}))(WarningRoundedIcon);

export const ErrorIcon = withStyles((theme) => ({
  root: {
    fill: theme.palette.error.main,
  },
}))(ErrorRoundedIcon);
