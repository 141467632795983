import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DevicesIcon from '@material-ui/icons/Devices';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import DashboardCard from '../DashboardCard';

export default function DevicesOnLocation({ location }) {
  const { t } = useTranslation();

  return (
    <>
      <DashboardCard
        title={t('components.devicesOnLocation.title')}
        minHeight={400}
      >
        <List>
          {/* If there is a deviceID which was already deleted that will be
          null so any device won't be visible */}
          {location?.devices[0] !== null ? (
            location?.devices.map((device, i) => (
              <ListItem
                button
                key={i}
                component={Link}
                to={`/devices/details/${device?._id}`}
              >
                <ListItemIcon>
                  <DevicesIcon />
                </ListItemIcon>
                <ListItemText primary={device?.name || <Skeleton />} />
              </ListItem>
            ))
          ) : (
            <Alert severity="info">no device found</Alert>
          )}
          {!location?.devices[0] && (
            <Alert severity="info">
              {t('components.devicesOnLocation.noDeviceFound')}
            </Alert>
          )}
        </List>
      </DashboardCard>
    </>
  );
}

DevicesOnLocation.propTypes = {
  location: PropTypes.object,
};
