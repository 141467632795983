import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  loadOpeningHours,
  selectOpeningHours,
} from '../LocationTable/locationSlice';

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

// ["mondayStart", "mondayEnd", ...];
const fields = days.flatMap((day) => {
  return [`${day}Start`, `${day}End`];
});

function getInitialHours() {
  const daysMap = fields.map((field) => [field, '']);
  return Object.fromEntries(daysMap);
}

export function loadHoursFromLocation(location) {
  const daysMap = fields.map((field, i) => [
    field,
    location?.openingHours?.days[`${Math.floor(i / 2) + 1}`]?.[
      `${field.includes('Start') ? `start` : `end`}`
    ],
  ]);
  return Object.fromEntries(daysMap);
}

export function createRequestBody(data) {
  const body = days.map((day, i) => {
    return [i + 1, getDayHours(day, data)];
  });
  return {
    days: Object.fromEntries(body),
  };
}

function getDayHours(day, data) {
  return {
    start: !data[`${day}Start`] ? '--:--' : data[`${day}Start`],
    end: !data[`${day}End`] ? '--:--' : data[`${day}End`],
  };
}

function capitalize(string) {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function OpeningHours({ location }) {
  const dispatch = useDispatch();
  const [data, setData] = useState(getInitialHours());
  // const dataNew = useSelector(selectOpeningHours);
  const { t } = useTranslation();

  useEffect(() => {
    if (location !== undefined) {
      setData(loadHoursFromLocation(location));
      dispatch(loadOpeningHours(loadHoursFromLocation(location)));
    }
  }, [location, dispatch]);

  function handleChange(e) {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    dispatch(
      loadOpeningHours({
        ...data,
        [e.target.name]: e.target.value,
      }),
    );
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        {days.map((day, i) => (
          <div
            key={i}
            style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
          >
            <Typography style={{ minWidth: 135 }}>
              {t(`components.openingHours.days.${day}`)}
            </Typography>
            <TextField
              type="time"
              variant="outlined"
              name={`${day}Start`}
              value={data[`${day}Start`]}
              onChange={handleChange}
            />
            <TextField
              type="time"
              variant="outlined"
              name={`${day}End`}
              value={data[`${day}End`]}
              onChange={handleChange}
            />
          </div>
        ))}
      </div>
    </>
  );
}

OpeningHours.propTypes = {
  location: PropTypes.object,
};
