import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import DevicePageNav from '../../components/DevicePageNav';
import EventLogTable from '../../components/EventLogTable';
import { useDevice } from '../../utils/useDevice';

export default function DeviceEventLogPage() {
  const { deviceId } = useParams();
  const device = useDevice(deviceId);
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${device?.name || t('general.loading')} | Event Log`}</title>
      </Helmet>
      <DevicePageNav deviceId={deviceId} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EventLogTable deviceId={deviceId} />
        </Grid>
      </Grid>
    </>
  );
}
