import HttpClient from '../utils/httpClient';
import config from '../config';

let httpClient = new HttpClient(config.baseUrlAuth);

export default {
  login,
  changeLanguage,
  me,
  changePassword,
};

async function login(credentials) {
  return await httpClient.post('/users/login', credentials);
}

async function changeLanguage(userId, body) {
  return await httpClient.put(`/users/language/${userId}`, body);
}

async function me() {
  return await httpClient.get(`/users/me/`);
}

async function changePassword(body) {
  return await httpClient.put(`/users/change-password`, body);
}
