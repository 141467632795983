import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  editIconButton: {
    marginRight: 8,
    position: 'absolute',
    right: 16,
    top: 16,
  },
  details: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  otherDetails: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginBottom: '1rem',
    '& .item::after': {
      content: '--',
      width: '20px',
      height: '20px',
      backgroundColor: 'red',
    },
  },
  badge: {
    border: '1px solid',
    borderRadius: '6px',
    padding: '2px 4px',
    color: theme.palette.common.white,
  },

  activeBadge: {
    backgroundColor: theme.palette.success.main,
  },

  standbyBadge: {
    backgroundColor: theme.palette.warning.light,
  },
}));

export default useStyles;
