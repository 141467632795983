import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
  },
  videoList: {
    border: '1px solid lightgrey',
    borderRadius: 4,
    backgroundColor: '#fafafa',
    minHeight: 100,
    padding: 8,
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  videoListItem: {
    marginBottom: theme.spacing(1),
  },
}));

export default useStyles;
