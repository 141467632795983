import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import CardTitle from '../../components/CardTitle';
import Typography from '@material-ui/core/Typography';

export default function PlaylistForm({
  nickname,
  setNickname,
  loading,
  selectedDevice,
  muiDate,
  handleMuiDate,
  startImmediately,
  handleSwitch,
}) {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        margin="normal"
        autoFocus
        fullWidth
        label={t('pages.createPlaylist.name')}
        variant="outlined"
        value={nickname}
        onChange={(e) => setNickname(e.target.value)}
      />
      {/* Jelenleg nem lehetséges eszközt választani */}
      {/* <TextField
        margin="normal"
        fullWidth
        label={t('pages.createPlaylist.selectDevices')}
        variant="outlined"
        value={loading ? `loading...` : selectedDevice?.name}
        disabled
      /> */}
      <CardTitle customMargin="40px 0">
        {t('pages.createPlaylist.whenToPlay')}
      </CardTitle>
      <Typography>
        {t('components.playlistForm.startTimeCurrentlySet')}:
      </Typography>
      <KeyboardDateTimePicker
        value={muiDate}
        onChange={handleMuiDate}
        inputVariant="outlined"
        ampm={false}
        disabled={startImmediately}
        disablePast
        format="kkkk.LL.dd - T"
        style={{ display: 'block', margin: '1rem 0' }}
      />
      <FormControlLabel
        control={
          <Switch
            checked={startImmediately}
            onChange={handleSwitch}
            name="immediately"
            color="primary"
          />
        }
        label={t('pages.createPlaylist.immediately')}
        style={{ display: 'block', margin: '1rem 0' }}
      />
    </>
  );
}
