import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import CreateLocation from '../../components/CreateLocation';
import { siteMetadata } from '../../constants';

const CreateLocationPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${t('components.createLocation.title')} | ${
          siteMetadata.TITLE
        }`}</title>
      </Helmet>
      <Grid container>
        <Grid item xs={12} md={6}>
          <CreateLocation />
        </Grid>
      </Grid>
    </>
  );
};

export default CreateLocationPage;
