import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DashboardCard from '../DashboardCard';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MuiErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import {
  NotActivatedIcon,
  OkIcon,
  StandbyIcon,
  WarningIcon,
  ErrorIcon,
} from '../DevicesWithProblems/DevicesWithProblems';

const HorizontalList = withStyles((theme) => ({
  root: {
    display: 'flex',
  },
}))(List);

export default function DeviceStatusBar({ devices }) {
  const { t } = useTranslation();
  function getStatus() {
    return devices?.reduce((acc, device) => {
      const existingStatus = acc[device.status];
      if (existingStatus) {
        acc[device.status] += 1;
      } else {
        acc[device.status] = 1;
      }
      return acc;
    }, {});
  }

  // const statuses = ['Not Activated', 'OK', 'Standby', 'Warning', 'Error'];

  return (
    <>
      <DashboardCard title={t('components.deviceStatusBar.title')}>
        {!devices && <p>Loading...</p>}
        {devices?.length && (
          <HorizontalList>
            {/* {statuses.map((status, i) => (
              <ListItem key={i} button style={{ display: 'flex' }}>
                <ListItemIcon>
                  {status === 'Not Activated' && <NotActivatedIcon />}
                  {status === 'OK' && <OkIcon />}
                  {status === 'Standby' && <StandbyIcon />}
                  {status === 'Warning' && <WarningIcon />}
                  {status === 'Error' && <ErrorIcon />}
                </ListItemIcon>
                <ListItemText primary={status} />
              </ListItem>
            ))} */}
            {getStatus()?.ok && (
              <ListItem style={{ display: 'flex' }}>
                <ListItemIcon>
                  <OkIcon />
                </ListItemIcon>
                <ListItemText primary={getStatus()?.ok} />
              </ListItem>
            )}
            {getStatus()?.standby && (
              <ListItem style={{ display: 'flex' }}>
                <ListItemIcon>
                  <StandbyIcon />
                </ListItemIcon>
                <ListItemText primary={getStatus()?.standby} />
              </ListItem>
            )}
            {getStatus()?.warning && (
              <ListItem style={{ display: 'flex' }}>
                <ListItemIcon>
                  <WarningIcon />
                </ListItemIcon>
                <ListItemText primary={getStatus()?.warning} />
              </ListItem>
            )}
            {getStatus()?.error && (
              <ListItem style={{ display: 'flex' }}>
                <ListItemIcon>
                  <ErrorIcon />
                </ListItemIcon>
                <ListItemText primary={getStatus()?.error} />
              </ListItem>
            )}
            {getStatus()['not activated'] && (
              <ListItem style={{ display: 'flex' }}>
                <ListItemIcon>
                  <NotActivatedIcon />
                </ListItemIcon>
                <ListItemText primary={getStatus()['not activated']} />
              </ListItem>
            )}
            {/* {Object.keys(getStatus()).map((status, i) => (
              <ListItem key={i} button style={{ display: 'flex' }}>
                <ListItemIcon>
                  <VerifiedUserIcon />
                </ListItemIcon>
                <ListItemText primary={status} />
              </ListItem>
            ))} */}
          </HorizontalList>
        )}
      </DashboardCard>
    </>
  );
}

DeviceStatusBar.propTypes = {
  devices: PropTypes.array,
};
