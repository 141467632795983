import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    gap: '1rem',
    marginTop: 'auto',
  },
}));

export default useStyles;
