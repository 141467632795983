import React, { useState, useEffect } from 'react';
import { useHistory, Link, useParams } from 'react-router-dom';
import useStyles from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { loadAlert } from '../../userSlice';
import { DateTime } from 'luxon';
import { Helmet } from 'react-helmet';
import anyValueIsEmpty from '../../utils/anyValueIsEmpty';
import PlaylistService from '../../services/PlaylistService';
import DeviceService from '../../services/DeviceService';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Paper from '@material-ui/core/Paper';
// import Switch from '@material-ui/core/Switch';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import { KeyboardDateTimePicker } from '@material-ui/pickers';
import Alert from '@material-ui/lab/Alert';
import ProgressBackdrop from '../../components/ProgressBackdrop';
// import CardTitle from '../../components/CardTitle';
import DragNDrop from '../../components/DragNDrop';
import {
  selectDndItems,
  setDndItems,
  selectDndButton,
  setDndButton,
} from '../../components/VideoTable/videoSlice';
import DashboardCard from '../../components/DashboardCard';
import PlaylistForm from '../../components/PlaylistForm';

const CreatePlaylist = (props) => {
  const { deviceId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [nickname, setNickname] = useState('');
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [startTime, setStartTime] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [startImmediately, setStartImmediately] = useState(false);
  const [muiDate, setMuiDate] = useState(DateTime.local().toISO());
  const [loading, setLoading] = useState(true);
  const [deviceName, setDeviceName] = useState('');

  // const user = useSelector(selectLoggedInUser);
  const history = useHistory();
  const classes = useStyles();
  const dndItems = useSelector(selectDndItems);
  const dndButton = useSelector(selectDndButton);
  const isSubmitDisabled = anyValueIsEmpty(
    nickname,
    selectedDevice,
    startTime || startImmediately || muiDate,
    dndItems.length,
    dndButton.disabled,
  );

  const handleMuiDate = (dt) => {
    setMuiDate(dt.toISO());
  };

  useEffect(() => {
    DeviceService.get(deviceId).then((device) => {
      setSelectedDevice(device);
      setDeviceName(device.name);
      setLoading(false);
    });
  }, [deviceId]);

  const handleSwitch = (e) => {
    // TODO: simplify this
    setStartImmediately(e.target.checked);
    setStartTime(e.target.checked ? DateTime.local().toISO() : selectedDate);
  };

  const handleSubmit = () => {
    setLoading(true);

    const reqBody = {
      nickname,
      startTime: startTime ? startTime : muiDate,
      videos: dndItems.map((video) => video._id),
      devices: [selectedDevice._id],
    };

    PlaylistService.create(reqBody)
      .then((data) => {
        setLoading(false);
        dispatch(
          setDndButton({
            text: 'Done',
            disabled: false,
          }),
        );
        history.push(`/devices/playlists/${deviceId}`);
      })
      .then((_) => {
        dispatch(
          loadAlert({
            message: `${t('pages.createPlaylist.playlistCreation.success')}`,
            type: 'success',
          }),
        );
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  return (
    <>
      <Helmet>
        <title>Create Playlist</title>
      </Helmet>
      {!loading && !selectedDevice?.activated ? (
        <Alert severity="error" style={{ margin: '1rem 0' }}>
          {t('pages.createPlaylist.notActivated')}{' '}
          <Link to={`/devices/playlists/${selectedDevice?._id}`}>
            {t('pages.createPlaylist.activateNow')}
          </Link>
        </Alert>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <DashboardCard
              title={t('pages.createPlaylist.addNew', { deviceName })}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <PlaylistForm
                    nickname={nickname}
                    setNickname={setNickname}
                    loading={loading}
                    selectedDevice={selectedDevice}
                    muiDate={muiDate}
                    handleMuiDate={handleMuiDate}
                    startImmediately={startImmediately}
                    handleSwitch={handleSwitch}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DragNDrop
                    playlistMaxSize={selectedDevice?.playlistMaxSize}
                  />
                  <Button
                    size="large"
                    disableElevation
                    className={classes.submitButton}
                    variant="contained"
                    color="primary"
                    disabled={isSubmitDisabled}
                    onClick={handleSubmit}
                  >
                    {t('pages.createPlaylist.create')}
                  </Button>
                  <Button
                    component={Link}
                    size="large"
                    disableElevation
                    className={classes.submitButton}
                    color="primary"
                    onClick={() => dispatch(setDndItems([]))}
                    to={`/devices/playlists/${deviceId}`}
                  >
                    {t('pages.createPlaylist.cancel')}
                  </Button>
                </Grid>
              </Grid>
            </DashboardCard>
          </Grid>
        </Grid>
      )}
      <ProgressBackdrop open={loading} />
    </>
  );
};

export default CreatePlaylist;
