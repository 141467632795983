import React from 'react';
import { Helmet } from 'react-helmet';
import capitalize from 'capitalize';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DeviceTable from '../../components/DeviceTable';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { siteMetadata } from '../../constants';

const MediaDevices = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>{`${capitalize(t('components.menuItems.devices'))} | ${
          siteMetadata.TITLE
        }`}</title>
      </Helmet>
      <Button
        disableElevation
        variant="contained"
        color="secondary"
        style={{ marginBottom: 16, marginRight: 16 }}
        component={Link}
        to={`${url}/new`}
      >
        {t('pages.mediaDevices.addNew')}
      </Button>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DeviceTable />
        </Grid>
      </Grid>
    </>
  );
};

export default MediaDevices;
