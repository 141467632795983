import React from 'react';
import useStyles from './styles';
import clsx from 'clsx';
import { Drawer, IconButton, Divider } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuItems from '../MenuItems';
import { useSelector, useDispatch } from 'react-redux';
import { closeMenu, selectIsSideMenuOpen } from './sideMenuSlice';
import { selectContext } from '../../contextSlice';

const SideMenu = () => {
  const isSideMenuOpen = useSelector(selectIsSideMenuOpen);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { logo } = useSelector(selectContext);

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !isSideMenuOpen && classes.drawerPaperClose,
        ),
      }}
      open={isSideMenuOpen}
    >
      <div className={classes.toolbarIcon}>
        <img src={logo} alt="logo" style={{ width: '55%', padding: 10 }} />
        <IconButton onClick={() => dispatch(closeMenu())}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <MenuItems />
    </Drawer>
  );
};

export default SideMenu;
