import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import { emptyAlert } from '../../userSlice';

const AlertBar = ({ type, message }) => {
  const [open, setOpen] = useState(true);

  const dispatch = useDispatch();

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    dispatch(emptyAlert());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <MuiAlert
        onClose={handleClose}
        severity={type || 'error'}
        elevation={1}
        variant="filled"
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

AlertBar.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string.isRequired,
};

export default AlertBar;
