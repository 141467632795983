import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'videosList',
  initialState: {
    videos: [],
    isVideosLoaded: false,
    videosLoadingError: null,
    dndItems: [],
    dndButton: { text: 'Ok', disabled: false },
  },
  reducers: {
    loadVideos: (state, action) => {
      // TODO: improve error handling
      if (action.payload === 'Not found') {
        state.videosLoadingError =
          "Couldn't get any data from the server on that address";
        state.isVideosLoaded = true;
      } else {
        state.videos = action.payload;
        state.isVideosLoaded = true;
        state.videosLoadingError = null;
      }
    },
    emptyVideos: (state, action) => {
      state.videos = [];
      state.isVideosLoaded = false;
      state.videosLoadingError = null;
    },
    catchError: (state, action) => {
      state.videosLoadingError = action.payload;
      state.isVideosLoaded = true;
    },
    setDndItems: (state, action) => {
      state.dndItems = action.payload;
    },

    setDndButton: (state, action) => {
      state.dndButton = action.payload;
    },
  },
});

export const selectVideos = (state) => state.videosList.videos;
export const selectVideosLoadingError = (state) =>
  state.videosList.videosLoadingError;
export const selectIsVideosLoaded = (state) => state.videosList.isVideosLoaded;
export const selectDndItems = (state) => state.videosList.dndItems;
export const selectDndButton = (state) => state.videosList.dndButton;

export const {
  loadVideos,
  catchError,
  emptyVideos,
  setDndItems,
  setDndButton,
} = slice.actions;

export default slice.reducer;
