import React from 'react';
import TextField from '@material-ui/core/TextField';

const InputField = ({ label, disabled, ...props }) => {
  return (
    <TextField
      label={label}
      margin="normal"
      fullWidth
      variant="outlined"
      disabled={disabled}
      {...props}
    />
  );
};

export default InputField;
