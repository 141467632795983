import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  input: {
    display: 'none',
  },
  buttonSection: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: 0,
    },
    '& > *': {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        width: '100%',
        margin: '16px 0 0 0',
        textAlign: 'center',
      },
    },
  },
  fileBrowser: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
  },
  fileName: {
    flex: 1,
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
