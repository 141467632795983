import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  LoadScript,
  Marker,
  GoogleMap,
} from '@react-google-maps/api';
import { useForm, Controller } from 'react-hook-form';
import { withStyles } from '@material-ui/core/styles';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import DashboardCard from '../DashboardCard';
import InputField from '../InputField';
import useFormInput from '../../utils/useFormInput';
import { getAddress } from './getAddress';
import LocationService from '../../services/LocationService';
import PartnerService from '../../services/PartnerService';
import { loadAlert } from '../../userSlice';
import OpeningHours from '../OpeningHours';
import CardTitle from '../CardTitle';
import { createRequestBody } from '../OpeningHours/OpeningHours';
import { selectOpeningHours } from '../LocationTable/locationSlice';
import DeniedAdsInput from '../DeniedAdsInput';

const libraries = ['places'];
const fields = ['place_id', 'geometry', 'name', 'address_component'];
const componentRestrictions = { country: ['HU'] };

export default function CreateLocation() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { handleSubmit, register, errors, control } = useForm();
  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState(``);
  const [longitude, setLongitude] = useState(``);
  const [latitude, setLatitude] = useState(``);
  const [isAddressInvalid, setIsAddressInvalid] = useState(false);
  const [partners, setPartners] = useState(null);
  // const [selectedPartnerId, setSelectedPartnerId] = useState('');
  const [loading, setLoading] = useState(true);
  // TODO: Kell ide a useFormInput egyáltalán?
  const name = useFormInput('');
  const activity = useFormInput('');
  const hours = useSelector(selectOpeningHours);
  const [deniedAds, setDeniedAds] = useState([]);

  useEffect(() => {
    PartnerService.get()
      .then((partners) => {
        setPartners(partners);
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      if (!autocomplete.getPlace().geometry) {
        setIsAddressInvalid(true);
      } else {
        const place = autocomplete.getPlace();
        setLongitude(place.geometry.location.lng());
        setLatitude(place.geometry.location.lat());
        setAddress(getAddress(place));
        setIsAddressInvalid(false);
      }
    } else {
      console.warn('Autocomplete is not loaded yet!');
    }
  };

  const resetFields = () => {
    setLatitude(``);
    setLongitude(``);
    setAddress(``);
    setIsAddressInvalid(false);
  };

  const onSubmit = (data) => {
    const openingHours = createRequestBody(hours);
    const reqBody = {
      name: data.name,
      address: address,
      coordinates: [longitude, latitude],
      activity: data.activity,
      partner: data.partner,
      openingHours,
      deniedAds,
    };

    if (
      reqBody.coordinates[0] &&
      reqBody.name &&
      reqBody.activity &&
      reqBody.partner
    ) {
      LocationService.create(reqBody)
        .then((res) => {
          dispatch(
            loadAlert({
              type: 'info',
              message: `${res.name} was created successfully`,
            }),
          );
          history.push('/locations');
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            loadAlert({
              message: err.message,
            }),
          );
        });
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <DashboardCard title={t('components.createLocation.title')}>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <InputField
          label={t('components.createLocation.form.name.label')}
          {...name}
          name="name"
          placeholder={t('components.createLocation.form.name.placeholder')}
          inputRef={register({
            required: `${t('components.createLocation.form.name.required')}`,
          })}
          error={Boolean(errors.name)}
          helperText={errors.name && errors.name.message}
        />
        <InputField
          label={t('components.createLocation.form.activity.label')}
          {...activity}
          name="activity"
          placeholder={t('components.createLocation.form.activity.placeholder')}
          inputRef={register({
            required: `${t(
              'components.createLocation.form.activity.required',
            )}`,
          })}
          error={Boolean(errors.activity)}
          helperText={errors.activity && errors.activity.message}
        />
        {/* Denied Ads */}
        <DeniedAdsInput deniedAds={deniedAds} setDeniedAds={setDeniedAds} />
        {/* Partner select */}
        <ReactHookFormSelect
          id="partner-select"
          name="partner"
          label="Partner"
          control={control}
          defaultValue={''}
          variant="outlined"
          margin="normal"
        >
          <MenuItem value="">None</MenuItem>
          {partners?.length &&
            partners.map((partner) => (
              <MenuItem key={partner._id} value={partner._id}>
                {partner.name}
              </MenuItem>
            ))}
        </ReactHookFormSelect>
        {/* Opening Hours */}
        <div style={{ margin: '2rem 0 1rem' }}>
          <CardTitle>
            {t('components.createLocation.addOpeningHours')}
          </CardTitle>
        </div>
        <OpeningHours />
        {/* Google Map and Preview */}
        <LoadScript
          googleMapsApiKey={process.env.GOOGLE_API_KEY}
          libraries={libraries}
        >
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceChanged}
            fields={fields}
            restrictions={componentRestrictions}
          >
            <InputField
              label={t('components.createLocation.form.map.label')}
              placeholder={t('components.createLocation.form.map.placeholder')}
              onChange={resetFields}
              error={isAddressInvalid || Boolean(errors.map)}
              helperText={
                (isAddressInvalid &&
                  `${t(
                    'components.createLocation.form.map.invalidAddress',
                  )}`) ||
                (errors.map && errors.map.message)
              }
              name="map"
              inputRef={register({
                required: `${t('components.createLocation.form.map.required')}`,
              })}
            />
          </Autocomplete>
        </LoadScript>
        <fieldset
          style={{
            padding: 16,
            borderRadius: 4,
            borderStyle: 'dashed',
            backgroundColor: '#F0F2F5',
          }}
        >
          <legend style={{ padding: 4 }}>
            {t('components.createLocation.preview')}
          </legend>
          <PreviewText variant="h6" style={{ fontWeight: 700 }}>
            {name.value}
            {activity.value && (
              <span>
                {' '}
                | <span style={{ fontWeight: 300 }}>{activity.value}</span>
              </span>
            )}
          </PreviewText>
          <PreviewText style={{ fontWeight: 300 }}>{address}</PreviewText>
          {longitude && latitude && (
            <GoogleMap
              mapContainerStyle={{
                height: '280px',
                width: '100%',
                borderRadius: 4,
                border: '1px double lightgrey',
                marginTop: 24,
              }}
              zoom={18}
              center={{
                lat: latitude,
                lng: longitude,
              }}
            >
              <Marker
                position={{
                  lat: latitude,
                  lng: longitude,
                }}
              />
            </GoogleMap>
          )}
        </fieldset>
        {/* Buttons */}
        <div style={{ marginTop: '2rem' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            style={{ marginRight: '1rem' }}
          >
            {t('components.createLocation.buttons.create')}
          </Button>
          <Button
            type="button"
            component={Link}
            to={`/locations`}
            variant="contained"
            disableElevation
          >
            {t('components.createLocation.buttons.cancel')}
          </Button>
        </div>
      </form>
    </DashboardCard>
  );
}

const PreviewText = withStyles((theme) => ({
  root: {
    marginBottom: 8,
  },
}))(Typography);

function ReactHookFormSelect({
  name,
  label,
  control,
  defaultValue,
  children,
  ...props
}) {
  const labelId = `${name}-label`;
  return (
    <FormControl {...props} fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        as={
          <Select labelId={labelId} label={label}>
            {children}
          </Select>
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required: true }}
      />
    </FormControl>
  );
}
