export const deviceStatuses = {
  OK: 'ok',
  STANDBY: 'standby',
  NOT_ACTIVATED: 'not activated',
  WARNING: 'warning',
  ERROR: 'error',
};

export const siteMetadata = {
  TITLE: 'AdFlyer',
};
