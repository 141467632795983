import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import DeviceService from '../../services/DeviceService';
import LocationService from '../../services/LocationService';
import { loadLocations, selectLocations } from '../LocationTable/locationSlice';
import Dialog from '../Dialog';
import GoogleMapDevices from '../GoogleMapDevices';
import DeniedAds from '../DeniedAds';

export default function DeviceLocation({ deviceId }) {
  const dispatch = useDispatch();
  const locations = useSelector(selectLocations);
  const [loading, setLoading] = useState(true);
  const [device, setDevice] = useState(null);
  const [devices, setDevices] = useState(null);
  const [location, setLocation] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const getDevice = DeviceService.get(deviceId);
    const getLocations = LocationService.get();
    const getDevices = DeviceService.get();

    Promise.all([getDevice, getLocations, getDevices])
      .then(([device, locations, devices]) => {
        setDevice(device);
        setDevices(devices);
        dispatch(loadLocations(locations));
        setLoading(false);
      })
      .catch(console.error);
  }, [deviceId, dispatch]);

  const assignLocation = async () => {
    setLoading(true);
    try {
      await DeviceService.update(deviceId, {
        location: location._id,
      });
      const getDevice = await DeviceService.get(deviceId);
      setDevice(getDevice);
      setLoading(false);
      setIsDialogOpen(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton variant="rect" height={400} animation="wave" />
      ) : (
        <>
          {!device.location ? (
            <>
              <Box boxShadow={1} borderRadius={6}>
                <Alert severity="info" style={{ marginBottom: '1rem' }}>
                  {t('components.deviceLocation.noLocation')}
                </Alert>
              </Box>
              <MuiLink
                component={Button}
                variant="body2"
                onClick={() => {
                  setIsDialogOpen(true);
                }}
              >
                {t('components.deviceLocation.addLocation')}
              </MuiLink>
            </>
          ) : (
            <>
              <GoogleMapDevices
                devices={devices}
                title={t('components.deviceLocation.title')}
                zoom={17}
                center={{
                  lat: device?.location?.coordinates[1],
                  lng: device?.location?.coordinates[0],
                }}
                loading={loading}
                deviceLocation={device.location}
              />
              <MuiLink
                component={Button}
                variant="body2"
                onClick={() => {
                  setIsDialogOpen(true);
                }}
              >
                {t('components.deviceLocation.changeLocation')}
              </MuiLink>
              {device?.location && <DeniedAds location={device.location} />}
            </>
          )}
        </>
      )}
      <Dialog
        title={t('components.deviceLocation.dialog.title')}
        open={isDialogOpen}
        cancel={() => setIsDialogOpen(false)}
        accept={assignLocation}
      >
        <Autocomplete
          id="combo-box"
          autoComplete
          autoHighlight
          disableClearable
          getOptionLabel={(option) => option.name || ``}
          options={locations}
          onChange={(_, newValue) => setLocation(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t('components.deviceLocation.dialog.title')}`}
              placeholder={`Choose one`}
              variant="outlined"
            />
          )}
          style={{ margin: '16px 0 16px 0' }}
          value={device?.location && device.location}
        />
      </Dialog>
    </>
  );
}
