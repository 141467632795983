import React, { useState } from 'react';
import useStyles from './styles';
import { NavLink } from 'react-router-dom';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

const AppSelector = ({ apps, currentApp }) => {
  const appList = Object.keys(apps);
  const classes = useStyles();

  const iconComponent = ({ className }) => {
    return <ExpandMoreRoundedIcon className={className + ' ' + classes.icon} />;
  };

  const menuProps = {
    classes: {
      paper: classes.paper,
      list: classes.list,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  return (
    <FormControl variant="outlined" style={{ minWidth: 100 }}>
      <Select
        classes={{ root: classes.select }}
        MenuProps={menuProps}
        IconComponent={iconComponent}
        value={currentApp}
      >
        {appList.map((app) => (
          <MenuItem key={app} value={app} component={NavLink} to={`/${app}`}>
            {app}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AppSelector;
