import { useEffect, useState } from 'react';
import DeviceService from '../services/DeviceService';

export function useDevice(deviceId) {
  const [device, setDevice] = useState(null);

  useEffect(() => {
    DeviceService.get(deviceId)
      .then((data) => setDevice(data))
      .catch(console.error);
  }, [deviceId]);

  return device;
}
