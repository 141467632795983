import { createSlice } from '@reduxjs/toolkit';

const playlistSlice = createSlice({
  name: 'playlist',
  initialState: {
    playlists: [],
    isPlaylistsLoaded: false,
    playlistLoadingError: null,
  },
  reducers: {
    loadPlaylists: (state, action) => {
      // TODO: improve error handling
      if (action.payload === 'Not found') {
        state.playlistLoadingError =
          "Couldn't get any data from the server on that address";
        state.isPlaylistsLoaded = true;
      } else {
        state.playlists = action.payload;
        state.isPlaylistsLoaded = true;
        state.playlistLoadingError = null;
      }
    },
    emptyPlaylists: (state, action) => {
      state.playlists = [];
      state.isPlaylistsLoaded = false;
      state.playlistLoadingError = null;
    },
    catchError: (state, action) => {
      state.playlistLoadingError = action.payload;
      state.isPlaylistsLoaded = true;
    },
  },
});

export const selectPlaylists = (state) => state.playlists.playlists;
export const selectPlaylistLoadingError = (state) =>
  state.playlists.playlistLoadingError;
export const selectIsPlaylistsLoaded = (state) =>
  state.playlists.isPlaylistsLoaded;

export const {
  loadPlaylists,
  catchError,
  emptyPlaylists,
} = playlistSlice.actions;

export default playlistSlice.reducer;
