export function getAddress(place) {
  const address = `${
    place.address_components.find((item) => item.types.includes('postal_code'))
      ?.long_name || ``
  } ${
    place.address_components.find((item) =>
      item.types.includes('locality' || 'political'),
    )?.long_name || ``
  }, ${
    place.address_components.find((item) => item.types.includes('route'))
      ?.long_name || ``
  } ${
    place.address_components.find((item) =>
      item.types.includes('street_number'),
    )?.long_name || ``
  }`;

  return address.trim();
}
