import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import useViewport from '../../../utils/useViewport';
import { useSelector, useDispatch } from 'react-redux';
import {
  closeMenu,
  selectMobileBreakpoint,
  selectIsSideMenuOpen,
} from '../../SideMenu/sideMenuSlice';

import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const MenuItem = ({ menuName, menuPath, menuIcon, active }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;
  const selectedMenuItem = pathname.includes(`${menuPath}`);
  const { width } = useViewport();
  const mobileBreakpoint = useSelector(selectMobileBreakpoint);
  const isSideMenuOpen = useSelector(selectIsSideMenuOpen);

  return (
    <ListItem
      disabled={!active}
      button
      onClick={() =>
        width <= mobileBreakpoint && isSideMenuOpen
          ? dispatch(closeMenu())
          : null
      }
      component={Link}
      to={menuPath}
      selected={selectedMenuItem}
    >
      <ListItemIcon>
        <Icon>{menuIcon}</Icon>
      </ListItemIcon>
      <ListItemText
        primary={menuName.charAt(0).toUpperCase() + menuName.slice(1)}
      />
    </ListItem>
  );
};

MenuItem.propTypes = {
  menuName: PropTypes.string.isRequired,
  menuIcon: PropTypes.string,
  active: PropTypes.bool.isRequired,
  menuPath: PropTypes.string.isRequired,
  location: PropTypes.object,
};

export default MenuItem;
