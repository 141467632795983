import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import useStyles from '../../styles';

export default function PartnerDetails({ partner }) {
  const classes = useStyles();

  return (
    <>
      <div
        style={{
          background: '#fff',
          padding: 40,
          position: 'relative',
        }}
      >
        <Tooltip title={`Edit`} className={classes.editIconButton}>
          <IconButton component={Link} to={`/partners/update/${partner._id}`}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2>{partner.name}</h2>
        </div>
        <p>Cégnév: {partner.companyName}</p>
        <p>Cégjegyzékszám: {partner.companyRegNo}</p>
        <p>Adószám: {partner.vatRegNo}</p>
        <p>Székhely: {partner.billingAddress}</p>
        <p>Képviselő: {partner.representative}</p>
        <div
          style={{
            border: '1px dashed',
            padding: 8,
            margin: '-8px',
            marginTop: 32,
          }}
        >
          <p>Ügyintéző: {partner.contactPerson}</p>
          <p>
            Telefonszám: <a href={`tel:${partner.phone}`}>{partner.phone}</a>
          </p>
          <p>
            E-mail cím: <a href={`mailto:${partner.email}`}>{partner.email}</a>
          </p>
          <p>Levelezési cím: {partner.shippingAddress}</p>
        </div>
      </div>
    </>
  );
}

PartnerDetails.propTypes = {
  partner: PropTypes.object.isRequired,
};
