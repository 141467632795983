import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { siteMetadata } from '../../constants';
import capitalize from 'capitalize';
import SelectLanguage from '../../components/SelectLanguage';
import ChangePassword from '../../components/ChangePassword';

const UserSettings = () => {
  const { t } = useTranslation();
  const pageName = capitalize(t('components.menuItems.settings'));

  return (
    <>
      <Helmet>
        <title>{`${pageName} | ${siteMetadata.TITLE}`}</title>
      </Helmet>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="h5">{t('pages.userSettings.title')}</Typography>
          <SelectLanguage />
        </Grid>
        <Grid item xs={12} md={6}>
          <ChangePassword />
        </Grid>
      </Grid>
    </>
  );
};

export default UserSettings;
