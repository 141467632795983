import HttpClient from '../utils/httpClient';
import config from '../config';

let httpClient = new HttpClient(config.baseUrlDss);

export default {
  get,
  update,
  create,
  activate,
  remove,
  getPlaylists,
  getEventLog,
  getStatus,
};

async function get(deviceId = '') {
  return await httpClient.get(`/devices/${deviceId}`);
}

async function update(deviceId = '', requestBody) {
  return await httpClient.put(`/devices/update/${deviceId}`, requestBody);
}

async function create(requestBody) {
  return await httpClient.post(`/devices/new`, requestBody);
}

async function activate(requestBody) {
  return await httpClient.post(`/activations/activate-code`, requestBody);
}

async function remove(deviceId = '') {
  return await httpClient.delete(`/devices/delete/${deviceId}`);
}

async function getPlaylists(deviceId = '') {
  return await httpClient.get(`/devices/playlists/${deviceId}`);
}

async function getEventLog(deviceId = '') {
  return await httpClient.get(`/devices/event-log/${deviceId}`);
}

async function getStatus(deviceId = '') {
  return await httpClient.get(`/devices/status/${deviceId}`);
}
