import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DeviceService from '../../services/DeviceService';
import DashboardCard from '../DashboardCard';
import FormDialog from '../FormDialog';
import ProgressBackdrop from '../ProgressBackdrop';
import { loadAlert } from '../../userSlice';
import {
  SuccessCheckIcon,
  RedErrorIcon,
  YellowWarningIcon,
} from '../../pages/DashboardPage/DashboardPage';
import { useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { ActiveIcon, StandByIcon } from '../DeviceTable/DeviceTable';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { useDevice } from '../../utils/useDevice';
import clsx from 'clsx';
import { getReadableId } from '../../utils/getReadableId';
import {
  NotActivatedIcon,
  OkIcon,
  StandbyIcon,
  WarningIcon,
  ErrorIcon,
} from '../DevicesWithProblems/DevicesWithProblems';
import { deviceStatuses } from '../../constants';

export default function DeviceDetails({
  remoteDevice,
  deviceId,
  setEditState,
  editState,
}) {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const updatedDevice = useDevice(deviceId);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const [device, setDevice] = useState(remoteDevice);

  //Update device info when coming back from EditState

  // Check if device has been updated
  useEffect(() => {
    setDevice(updatedDevice);
  }, [editState, updatedDevice]);

  // refresh details every 5 seconds but only if the device is pending activation
  useEffect(() => {
    const interval = setInterval(() => {
      console.log('interval ran');
      DeviceService.get(deviceId)
        .then((res) => {
          setDevice(res);
          if (res.activated || (!res.activated && !res.activationCode)) {
            return clearInterval(interval);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }, 5000);
    return () => clearInterval(interval);
  }, [deviceId]);

  const getDeviceState = (activated, pending, notActivated, nothing) => {
    if (device) {
      if (device.activated) return activated;
      if (device.activationCode) return pending;
      return notActivated;
    }
    return nothing;
  };

  const handleSubmitCode = (activationCode) => {
    setDialogOpen(false);
    setLoading(true);

    DeviceService.activate({ ...activationCode, deviceId })
      .then((res) => {
        setLoading(false);
        setDevice(res);
        dispatch(
          loadAlert({
            type: 'info',
            message: `${t('components.deviceDetails.alerts.codeAccepted')}`,
          }),
        );
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        dispatch(
          loadAlert({
            type: 'error',
            message: `${t('components.deviceDetails.alerts.codeInvalid')}`,
          }),
        );
      });
  };

  return (
    <>
      {!device ? (
        <Skeleton variant="rect" height={400} animation="wave" />
      ) : (
        <>
          <DashboardCard
            title={t('components.deviceDetails.title')}
            minHeight="400px"
            position="relative"
          >
            <Tooltip title={`Edit`}>
              <IconButton
                onClick={() => setEditState(true)}
                className={classes.editIconButton}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <div className={classes.details}>
              <div
                style={{ display: 'flex', gap: 8, padding: 8, margin: '-8px' }}
              >
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  {device.status === deviceStatuses.NOT_ACTIVATED && (
                    <NotActivatedIcon />
                  )}
                  {device.status === deviceStatuses.OK && <OkIcon />}
                  {device.status === deviceStatuses.STANDBY && <StandbyIcon />}
                  {device.status === deviceStatuses.WARNING && <WarningIcon />}
                  {device.status === deviceStatuses.ERROR && <ErrorIcon />}
                </div>
                <Typography variant={`subtitle1`}>
                  {getReadableId(device.readableId)}
                </Typography>
              </div>
              <Typography variant={`h3`} gutterBottom>
                {device.name}
              </Typography>
              <div className={classes.otherDetails}>
                <Typography variant={`body1`} className={'item'}>
                  {t('components.deviceDetails.playlistMaxSize')}:{' '}
                  {device.playlistMaxSize}
                </Typography>
                <Typography variant={`body1`}>{device.type}</Typography>
                {/* <Typography variant={`body1`}>{device.company.name}</Typography> */}
                <Typography variant={`body1`}>
                  {t('components.deviceDetails.displayType')}:{' '}
                  {device?.displayType}
                </Typography>
                <Typography variant={`body1`}>
                  {t('components.deviceDetails.displaySize')}:{' '}
                  {device?.displaySize}
                </Typography>
              </div>
            </div>
            <div
              style={{ display: 'flex', marginTop: 8, alignItems: 'center' }}
            >
              {getDeviceState(
                <SuccessCheckIcon />,
                <YellowWarningIcon />,
                <RedErrorIcon />,
                null,
              )}
              <Typography
                style={{
                  flexGrow: 1,
                  marginLeft: 4,
                  alignSelf: 'center',
                  color: getDeviceState(
                    theme.palette.success.main,
                    theme.palette.warning.main,
                    theme.palette.error.main,
                    theme.palette.common.white,
                  ),
                }}
              >
                {getDeviceState(
                  `${t('components.deviceDetails.deviceStates.activated')}`,
                  `${t('components.deviceDetails.deviceStates.pending')}`,
                  `${t('components.deviceDetails.deviceStates.notActivated')}`,
                  '',
                )}
              </Typography>
              {device && !device.activationCode && (
                <>
                  <Button onClick={() => setDialogOpen(true)}>
                    {t('components.deviceDetails.activateNow')}
                  </Button>
                </>
              )}
            </div>
            <FormDialog
              open={dialogOpen}
              title={t('components.deviceDetails.formDialog.title')}
              text={t('components.deviceDetails.formDialog.text')}
              label={t('components.deviceDetails.formDialog.label')}
              fieldName={t('components.deviceDetails.formDialog.fieldName')}
              cancel={() => setDialogOpen(false)}
              handleSend={handleSubmitCode}
              registerDetails={{
                required: `${t(
                  'components.deviceDetails.formDialog.required',
                )}`,
                pattern: {
                  value: /^\d{6}$/,
                  message: `${t(
                    'components.deviceDetails.formDialog.pattern',
                  )}`,
                },
              }}
            />
            <ProgressBackdrop open={loading} />
          </DashboardCard>
        </>
      )}
    </>
  );
}

DeviceDetails.propTypes = {
  remoteDevice: PropTypes.object.isRequired,
  deviceId: PropTypes.string.isRequired,
  setEditState: PropTypes.func.isRequired,
  editState: PropTypes.bool.isRequired,
};
