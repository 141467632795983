import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { selectLoggedInUser, changeLanguage } from '../../userSlice';
import { useSelector, useDispatch } from 'react-redux';
import SaveButton from '../SaveButton';
import UserService from '../../services/UserService';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: `${theme.spacing(2)}px 0`,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SelectLanguage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const user = useSelector(selectLoggedInUser);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const [languageChanged, setLanguageChanged] = useState(false);
  const [error, setError] = useState(null);

  const handleLanguageChange = (e) => {
    setLanguageChanged(i18n.language !== e.target.value);
    setLanguage(e.target.value);
    setError(null);
  };

  const saveLanguageChange = async () => {
    const reqBody = { language };

    setLoading(true);

    try {
      UserService.changeLanguage(user.id, reqBody);
      setLanguageChanged(false);
      dispatch(changeLanguage(language));
    } catch (e) {
      setError(`Couldn't change the language!`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">
          {t('pages.userSettings.language')}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={language}
          onChange={handleLanguageChange}
          label={t('pages.userSettings.language')}
        >
          <MenuItem value={`en`}>English</MenuItem>
          <MenuItem value={`hu`}>Magyar</MenuItem>
        </Select>
      </FormControl>
      <SaveButton
        onClick={saveLanguageChange}
        editState={languageChanged}
        saving={loading}
      />
      {error ? (
        <Alert style={{ marginTop: 8 }} severity="error">
          {error}
        </Alert>
      ) : null}
    </div>
  );
};

export default SelectLanguage;
